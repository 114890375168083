import React, { useState } from "react";
import { useAlert } from 'react-alert';
import { Trans, useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Container, Row, Input, Alert } from "reactstrap";
import { redirect, useNavigate } from "react-router-dom";

//Import Image
import lightLogo from "../../../logo.svg";
import darkLogo from "../../../logo.svg";

import linkedInLogo from "../../../assets/images/social-media/linkedIn.png";
import fbLogo from "../../../assets/images/social-media/facebook.svg";
import googleLogo from "../../../assets/images/social-media/google.svg";
import { useGoogleLogin } from '@react-oauth/google';
import { useLoaderStore } from "../../../stores/Loader.store";

import signInImage from "../../../assets/images/illustrations/login-01.svg";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { validateEmail } from "../../../utils/common";
import { getAccessToken, setAccessToken, setLoginType, setRefreshToken, signin, getGoogleUserData, setProfileData } from "../../../services/User.service";
import useAuthStore from "../../../stores/Auth/Auth.store";

const SignIn = () => {
  const alert = useAlert()
  const { t } = useTranslation();
  document.title = t("signInPage.title");

  let navigate = useNavigate();
  const { isLoading, setIsLoading } = useLoaderStore(state => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isInvalid, setIsInvalid] = useState({
    isEmailValid: undefined,
    isPasswordValid: undefined
  });
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const { isLoggedIn, setIsLoggedIn } = useAuthStore(state => state);

  const [errorMessage, setErrorMessage] = useState("");
  const REACT_APP_BASE_BACKEND_URL = '<https://api-jobs-dev.talentbase.lk>';
  const redirectUriData = 'https://api-jobs-dev.talentbase.lk/api/v1/auth/login/google/'; //

  const scopeData = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ].join(' ');

  const googleLogin = useGoogleLogin(
    {
      flow: "auth-code",
      scope: scopeData,
      prompt: 'select_account',
      ux_mode: "redirect",
      redirect_uri: redirectUriData,
      onSuccess: codeResponse => googleResponseMessage(codeResponse),
      onError: codeResponse => console.log(codeResponse)
    });

  const googleResponseMessage = (response) => {
    setLoginType('google');
    setAccessToken(response.access_token);
    getGoogleUserDataRequest();
  };

  const getGoogleUserDataRequest = async () => {
    try {
      let response = await getGoogleUserData();
      setProfileData(response);
      welcomeSuccess();
      navigate('/profile');
    }

    catch (err) {
      if (err.detail) {
        setErrorMessage(err.detail);
        setIsOpenAlert(true);
        return;
      }
      setErrorMessage(t("signInPage.errorMessage.invalidEmail"));
      setIsOpenAlert(true);
    }
  }

  const welcomeSuccess = () => {
    let data = {
      type: "success",
      title: "Welcome back, User!",
      message: "You're all set to explore new job opportunities."
    }
    alert.show(JSON.stringify(data), {
      timeout: 10000, // custom timeout just for this one alert
      type: 'success',
      position: "top right",
      offset: '10px',
      containerStyle: {
        zIndex: 600
      }
    })
  }

  const googleErrorMessage = (error) => {
    setErrorMessage(t("signInPage.errorMessage.invalidEmail"));
    setIsOpenAlert(true);
  };

  const handleDismiss = () => {
    setIsOpenAlert(false);
  };

  const signInLocal = async () => {
    setIsLoading(true);
    setErrorMessage("");
    setIsOpenAlert(false);

    if (!validateEmail(email)) {
      setIsInvalid({
        isEmailValid: false
      });
      return;
    }

    let data = {
      "email": email,
      "password": password
    };

    try {
      let response = await signin(data);
      if (response) {
        let responseData = response;
        setAccessToken(responseData.access);
        setRefreshToken(responseData.refresh);
        setLoginType('local');
        setIsLoggedIn(true);
        welcomeSuccess();
        navigate('/home');
        return true;

      }
    }
    catch (err) {
      if (err.detail) {
        setErrorMessage(err.detail);
        setIsOpenAlert(true);
        return;
      }
      setErrorMessage(t("signInPage.errorMessage.invalidEmail"));
      setIsOpenAlert(true);
    }
    finally {
      setIsLoading(false);
    }
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                height="200"
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div>
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content px-5 py-3 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>{t("signInPage.welcomeBack")}</h5>
                                <p className="text-white-70">
                                  {t("signInPage.signInContinue")}
                                </p>
                              </div>
                              <div className="auth-form">
                                {errorMessage && isOpenAlert && (
                                  <Alert color="danger" toggle={handleDismiss} className="mt-3 text-danger">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {errorMessage}
                                  </Alert>
                                )}

                                {/* {errorMessage && isOpenAlert && (
                                  <Alert color="success" toggle={handleDismiss} className="mt-3 text-danger">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {errorMessage}
                                  </Alert>
                                )} */}

                                {isOpenSuccessAlert && (
                                  <Alert color="success" toggle={handleDismiss} className="mt-3 text-success">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {t("signInPage.loginSuccessful")}
                                  </Alert>
                                )}
                                <div className="mb-3">
                                  <label
                                    htmlFor="emailInput"
                                    className="form-label"
                                  >
                                    {t("signInPage.email")}
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-user"></i>
                                    </span>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="emailInput"
                                      placeholder={t("signInPage.enterEmail")}
                                      onChange={(e) => { setEmail(e.target.value) }}
                                      invalid={isInvalid.isEmailValid == false}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    {t("signInPage.password")}
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-lock"></i>
                                    </span>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="passwordInput"
                                      onChange={(e) => { setPassword(e.target.value) }}
                                      placeholder={t("signInPage.enterPassword")}
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                    />
                                    <Link
                                      to="/resetpassword"
                                      className="float-end text-white"
                                    >
                                      {t("signInPage.forgotPassword")}
                                    </Link>
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {t("signInPage.rememberMe")}
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    onClick={(e) => { signInLocal() }}
                                    className="btn btn-white btn-hover w-100"
                                  >
                                    {t("signInPage.signIn")}
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <p className="mb-0">{t("signInPage.orSignIn")}</p>
                                </div>
                                <ul className="candidate-detail-social-menu list-inline mb-0 mt-3 d-flex justify-content-center">
                                  {/* <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={linkedInLogo}
                                        height="24"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img src={fbLogo} height="35" alt="" />
                                    </Link>
                                  </li> */}
                                  <li className="list-inline-item">
                                    {/* <GoogleLogin onSuccess={googleResponseMessage} onError={googleErrorMessage} /> */}
                                    <Link
                                      onClick={(e) => googleLogin()}
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={googleLogo}
                                        height="22"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  {t("signInPage.dontHaveAccount")}{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    {t("signInPage.signUp")}{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;

import { create } from "zustand";

const isOpenNotificationSidePanelStore = (set, get) => ({
    isNotificationSidePanelOpen: false,
    setIsNotificationSidePanelOpen: (isNotificationSidePanelOpen) => {
        set(state => ({
            ...state,
            isNotificationSidePanelOpen
        }));
    },
});

const useIsNotificationSidePanelOpenStore = create(isOpenNotificationSidePanelStore);

export default useIsNotificationSidePanelOpenStore;
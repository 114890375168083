import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Input, Modal, ModalBody, Label, Button, Col, FormGroup } from "reactstrap";
import { Form } from "react-bootstrap";
import { updateProfile } from "../../../../services/User.service";

const ProfileEducation = ({ modal = false, openModal, callBackSubmit }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = Array.from(new Array(currentYear - startYear + 1), (val, index) => startYear + index);
  const educationData = {
    institutionName: "",
    degree: "",
    fieldOfStudy: "",
    startYear: currentYear,
    startMonth: new Date().getMonth(),
    endYear: currentYear,
    endMonth: new Date().getMonth()
  };

  const [educations, setEducations] = useState([educationData]);

  const onSubmitEvent = async (e) => {
    e.preventDefault();
    await updateEducation();
    callBackSubmit();
  }

  const addMore = (e) => {
    e.preventDefault();
    setEducations([...educations, ...[educationData]]);
  }

  const handleInputChange = (key, value, index) => {
    setEducations(educations => educations.map((education, i) => {
      if (i === index) {
        education[key] = value;
        return education;
      }
      else {
        return education;
      }
    }));
  };

  const updateEducation = async () => {
    try {
      let data = [];

      educations.map((education) => {
        data.push({
          institution_name: education.institutionName,
          degree: education.degree,
          field_of_study: education.fieldOfStudy,
          start_date: `${education.startYear}-${("0" + education.startMonth).slice(-2)}-01`,
          end_date: `${education.endYear}-${("0" + education.endMonth).slice(-2)}-01`
        });
      });

      let requestData = {
        education: data
      };

      return await updateProfile(requestData);

    }
    catch (err) {
      console.log(err);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    callBackSubmit()
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <Modal isOpen={modal} toggle={openModal} centered>
        <ModalBody className="modal-body p-5">
          <div className="d-flex">
            <div className="modal-header-icon me-3">
              <i
                className="uil uil-file-info-alt"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </div>
            <div className="mb-1">
              <h5 className="modal-title" id="staticBackdropLabel">
                {t("profileEducation.title")}
              </h5>
              <div>{t("profileEducation.addEducationDetails")}</div>
            </div>
          </div>
          <hr />

          <div className="position-absolute end-0 top-0 p-3">
            <button
              type="button"
              onClick={openModal}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <Form onSubmit={onSubmitEvent}>
            {educations && educations.length > 0 && educations.map((education, index) => {
              return (
                <div className="mb-3">
                  <h6 for="nameControlInput" className="form-label">
                    {t("profileEducation.education")} {index + 1}
                  </h6>
                  <FormGroup>
                    <Label for="universityInput" className="form-label">
                      {t("profileEducation.university")}
                    </Label>
                    <Input
                      type="text"
                      className="form-control mb-2"
                      id="universityInput"
                      placeholder={t("profileEducation.university")}
                      value={education.institutionName}
                      onChange={(e) => {
                        handleInputChange(
                          "institutionName",
                          e.target.value,
                          index
                        );
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="universityInput" className="form-label">
                      {t("profileEducation.degree")}
                    </Label>
                    <Input
                      type="text"
                      className="form-control mb-2"
                      id="nameControlInput"
                      placeholder={t("profileEducation.degree")}
                      value={education.degree}
                      onChange={(e) => {
                        handleInputChange("degree", e.target.value, index);
                      }}
                    />
                  </FormGroup>
                  <FormGroup row className="mb-2">
                    <Label for="yearRange" className="form-label col-sm-12">
                      {t("profileEducation.startDate")}
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="startYear"
                        id="startYear"
                        value={education.start}
                        onChange={(e) => {
                          handleInputChange("startYear", e.target.value, index);
                        }}
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="startMonth"
                        id="startMonth"
                        value={education.startMonth}
                        onChange={(e) => {
                          handleInputChange(
                            "startMonth",
                            e.target.value,
                            index
                          );
                        }}
                      >
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-2">
                    <Label for="yearRange" className="form-label col-sm-12">
                      {t("profileEducation.endDate")}
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="endYear"
                        id="endYear"
                        value={education.end}
                        onChange={(e) => {
                          handleInputChange("endYear", e.target.value, index);
                        }}
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="endMonth"
                        id="endMonth"
                        value={education.endMonth}
                        onChange={(e) => {
                          handleInputChange("endMonth", e.target.value, index);
                        }}
                      >
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label for="universityInput" className="form-label">
                      {t("profileEducation.description")}
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="nameControlInput"
                      placeholder={t("profileEducation.description")}
                    />
                  </FormGroup>
                </div>
              );
            })}

            <div>
              <button onClick={(e) => addMore(e)} type="submit" className="btn btn-primary w-100">
                + {t("profileEducation.addMore")}
              </button>
            </div>

            <hr />

            <div className="d-flex">
              <button onClick={e => closeModal(e)} className="btn btn-outline-primary w-100 me-2">
                {t("profileEducation.addLater")}
              </button>

              <button type="submit" className="btn btn-primary w-100">
                {t("profileEducation.next")}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfileEducation;
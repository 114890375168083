import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Row } from "reactstrap";
import useCourseGridStore from "../../stores/Courses/CourseGrid.store";
import CourseGridHeader from "./CourseHeader";
import CourseVacancyGrid from "./CourseGrid";
import CourseVacancyList from "./CourseList";
import Section from "./Section";
import Pagination from "./Pagination";
import Subscribe from "../Home/subscribe/Subscribe";
import Footer from "../Home/footer/Footer";

const Courses = () => {
  const { t } = useTranslation();
  const { isGrid } = useCourseGridStore(state => state);

  document.title = t("course.title");
  
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <CourseGridHeader />
          <Row>
            {isGrid ? <CourseVacancyGrid /> : <CourseVacancyList />}
          </Row>
        </Container>
      </section>
      <div className="main-content">
        {/* <Subscribe /> */}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Courses;
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Input, Label, FormGroup } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import useIsMessageSidePanelOpenStore from "../../../stores/SidePanel/MessageSidePanel.store";

function MessageRightSidePanel() {
  const { t } = useTranslation();
  const { isMessageSidePanelOpen, setIsMessageSidePanelOpen } = useIsMessageSidePanelOpenStore(state => state);
  const [isShowHistory, setIsShowHistory] = useState(true);
  const [isShowMessages, setIsShowMessages] = useState(false);

  const togglePanel = () => {
    setIsMessageSidePanelOpen(!isMessageSidePanelOpen);
  };

  const messageClick = () => {
    setIsShowHistory(false);
    setIsShowMessages(true);
  }

  const onBackClick = () => {
    setIsShowHistory(true);
    setIsShowMessages(false);
  }

  return (
    <div>
      {/* Button to trigger the side panel */}
      {/* <button
        className="btn btn-primary"
        onClick={togglePanel}
        aria-controls="offcanvasRight"
      >
        Open Right Panel
      </button> */}

      {/* Right Side Panel (Offcanvas) */}
      <div
        className={`offcanvas offcanvas-end ${isMessageSidePanelOpen ? "show" : ""}`}
        id="offcanvasRight"
        tabIndex="-1"
        aria-labelledby="offcanvasRightLabel"
        style={{ visibility: isMessageSidePanelOpen ? "visible" : "hidden" }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">{t("messageSidePanel.messages")}</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={togglePanel}
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="notification-header border-bottom bg-light fs-12">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="d-flex flex-wrap align-items-start gap-1">
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-bell fs-13"></i>
                  <span className="fs-13">{t("messageSidePanel.all")}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-envelope-alt fs-13"></i>
                  <span className="fs-13">{t("messageSidePanel.unread")}</span>
                </button>
              </div>
              <button
                type="button"
                class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 ms-auto"
              >
                <span className="fs-13">{t("messageSidePanel.delete")}</span>
              </button>
            </div>
            <div class="form-check mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
              />
              <label class="form-check-label fs-12" for="flexCheckDefault">
                {t("messageSidePanel.selectAll")}
              </label>
            </div>
            {isShowMessages && <span onClick={onBackClick} className='badge rounded-pill bg-primary cursor-pointer'>
              <i className="uil uil-arrow-left fs-14"></i>
            </span>}
          </div>
          {isShowHistory &&
            <div className="notification-wrapper dropdown-scroll">
              <Link
                onClick={messageClick}
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For unread msgs  */}
                    <h6 className="d-flex mt-0 mb-1 fs-14 gap-1">
                      <span>22 verified registrations</span>
                    </h6>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>3 min ago</span>
                    </p>
                  </div>
                  <div className="flex-shrink-0 ms-3">
                    <div className="text-muted rounded-circle text-center">
                      {/* <i className="uil uil-ellipsis-h"></i> */}
                    </div>
                  </div>
                </div>
              </Link>
              {/* Message sent from our side  */}
              <Link
                onClick={messageClick}
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For read msgs  */}
                    <div>22 verified registrations</div>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>3 min ago</span>
                    </p>
                  </div>

                </div>
              </Link>
              <Link
                onClick={messageClick}
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For read msgs  */}
                    <div>22 verified registrations</div>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>5 min ago</span>
                    </p>
                  </div>

                </div>
              </Link>
              <Link
                onClick={messageClick}
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For read msgs  */}
                    <div>22 verified registrations</div>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>4 min ago</span>
                    </p>
                  </div>

                </div>
              </Link>
            </div>
          }

          {isShowMessages &&
            <div className="notification-wrapper dropdown-scroll">
              <Link
                to="#"
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For read msgs  */}
                    <div>22 verified registrations</div>
                    <p className="mb-0 fs-12 text-muted">
                      <i className="mdi mdi-clock-outline"></i>{" "}
                      <span>3 min ago</span>
                    </p>
                  </div>
                  <div className="flex-shrink-0 ms-3">
                    <div className="text-muted rounded-circle text-center">
                      {/* <i className="uil uil-ellipsis-h"></i> */}
                    </div>
                  </div>
                </div>
              </Link>
              {/* Message sent from our side  */}
              <Link
                to="#"
                className="text-dark notification-item d-block active text-decoration-none"
              >
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-shrink-0 me-3">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="check01"
                      />
                    </div>
                  </div>
                  <div className="flex-grow-1">
                    {/* For unread msgs  */}
                    <div className="d-flex mt-0 mb-1 fs-14 gap-1 justify-content-end">
                      <span>22 verified registrations</span>
                    </div>
                  </div>
                  <div className="flex-shrink-0 ms-3">
                    <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                      <i className="uil uil-user-check"></i>
                    </div>
                  </div>
                </div>
              </Link>
              <div className="send-msg-section">
                {/* Write msg  */}
                <FormGroup>
                  <Label for="universityInput" className="form-label">
                    {t("messageSidePanel.message")}
                  </Label>
                  <Input
                    type="textarea"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Type your message here..."
                  />
                </FormGroup>
                <div className="text-end">
                  <button
                    type="button"
                    id="submit"
                    name="submit"
                    className="btn btn-primary"
                  >
                    {" "}
                    {t("messageSidePanel.send")}<i className="uil uil-message ms-1"></i>
                  </button>
                </div>
              </div>
            </div>
          }


        </div>
      </div>
    </div>
  );
}

export default MessageRightSidePanel;

import { create } from "zustand";

const isOpenMessageSidePanelStore = (set, get) => ({
  isMessageSidePanelOpen: false,
  setIsMessageSidePanelOpen: (isMessageSidePanelOpen) => {
    set(state => ({
      ...state,
      isMessageSidePanelOpen
    }));
  },
});

const useIsMessageSidePanelOpenStore = create(isOpenMessageSidePanelStore);

export default useIsMessageSidePanelOpenStore;
import { IndustryEndPoints, JobApiEndPoints } from '../constants/Api';
import { baseApi } from './Base.api';

export async function occupationAutoComplete() {
    try {
        const response = await baseApi.apiGetWithoutToken(JobApiEndPoints.autoCompleteOccupation);

        return response;
    } catch (error) {
        throw error;
    }
}

export async function fetchJobs(queryParams = {}) {
    try {
       
        const response = await baseApi.apiGetWithoutToken(JobApiEndPoints.jobList, { params: {...queryParams} });

        return response;
    } catch (error) {
        throw error;
    }
}

export async function fetchIndustries() {
    try {
        const response = await baseApi.apiGetWithoutToken(IndustryEndPoints.industry);

        return response;
    } catch (error) {
        throw error;
    }
}
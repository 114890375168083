import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";

//Import Images
import AboutImage from "../../../assets/images/illustrations/about-us.gif";

const About = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">{t("aboutUsPage.aboutUs")}</h6>
                <h2 className="title mb-4">
                  Why TalentBase?
                </h2>
                <p className="text-muted">
                  {t("aboutUsPage.description")}
                </p>

                <Row mt={4} pt={2}>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                      <li> {t("aboutUsPage.extensiveJobListing")}</li>
                      <li> {t("aboutUsPage.userFriendlyInterface")}</li>
                      <li> {t("aboutUsPage.advancedSearch")}</li>
                      <li> {t("aboutUsPage.employerBranding")}</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted">
                      <li> {t("aboutUsPage.efficentRecruitment")}</li>
                      <li> {t("aboutUsPage.twentySupport")}</li>
                      <li> {t("aboutUsPage.mobileAccess")}</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img src={AboutImage} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody } from "reactstrap";
import {getAccessToken} from "../../../services/User.service";

//Images Import
import jobImage1 from "../../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../../assets/images/featured-job/img-07.png";
import companyImage from "../../../assets/images/default-image.jpg";

import { fetchJobs } from "../../../services/Job.service";

const JobVacancyList = () => {
  const { t } = useTranslation();
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [jobVacancyList, setJobVacancyList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSegement, setPageSegement] = useState(1);

  const TotalSegment = 4;


  useEffect(() => {
    getJobList();
  }, []);

  useEffect(() => {
    if (page) {
      getJobList();
    }
  }, [page]);

  const getJobList = async () => {
    try {
      let paramData = { page: page };
      let response = await fetchJobs(paramData);
      if (response) {

        let tempData = [];

        setTotalPage(response.count / 10);

        response.results.forEach((data) => {
          tempData.push({
            id: data.id,
            companyImg: data.url,
            jobDescription: data.title,
            companyName: data.company,
            location: data.location,
            jobPostTime: data.time_since,
            fullTime: data.job_type == 'Full Time' ? true : false,
            timing: data.job_type,
            addclassNameBookmark: false,
            badges: [],
            experience: data.experience_needed,
            salary: `${data.salary_currency}${data.salary}/Month`,
            matchingPercentage: 80,
            jobLongDescription: data.description
          });
        });

        setJobVacancyList(tempData);
        return true;

      }
    }
    catch (err) {
      console.log(err);
    }
  }

  const changePage = (pageCount) => {
    setPage(pageCount);
  }

  const incrementSegement = (page) => {
    let pageSegementData = pageSegement + page;
    setPageSegement(pageSegementData);
    setPage(((pageSegementData - 1) * TotalSegment) + 1);
  }

  const decreaseSegement = (page) => {
    let pageSegementData = pageSegement - page;
    setPageSegement(pageSegementData);
    setPage(((pageSegementData - 1) * TotalSegment) + TotalSegment);
  }

  const pagination = () => {
    let pageTag = [];
    let startIterate = ((pageSegement - 1) * TotalSegment) + 1;
    let iterate = pageSegement * TotalSegment;
    for (let i = (startIterate - 1); i < iterate; i++) {
      pageTag.push(<li onClick={(e) => changePage(i + 1)} className={`page-item ${page == (i + 1) ? "active" : ""}`}>
        <Link className="page-link" to="#">
          {i + 1}
        </Link>
      </li>);
    }

    return pageTag;
  }


  return (
    <React.Fragment>
      <div>
        {jobVacancyList.map((jobVacancyListDetails, key) => (
          <div
            key={key}
            className={
              jobVacancyListDetails.addclassNameBookmark === true
                ? "job-box bookmark-post card mt-4"
                : "job-box card mt-4"
            }
          >
            <div className="bookmark-label text-center">
              <Link to="#" className="align-middle text-white">
                <span className="fs-8">{t("job.urgent")}</span>
              </Link>
            </div>
            <div className="px-5 py-4">
              <Row>
                <Col lg={1}>
                  <Link to="/job-details">
                    <img
                      src={jobVacancyListDetails.imageUrl || companyImage}
                      alt=""
                      className="img-fluid rounded-3"
                    />
                  </Link>
                </Col>
                <Col lg={11}>
                  <div className="mt-3 mt-lg-0">
                    <h5 className="fs-17 mb-1">
                      <Link to="/job-details" className="text-dark">
                        {jobVacancyListDetails.jobDescription}
                      </Link>{" "}
                    </h5>
                    <Link to="/job-details">
                      <div className="d-md-flex justify-content-between">
                        <div>
                          <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                              <p className="text-muted fs-14 mb-0">
                                {jobVacancyListDetails.companyName}
                              </p>
                            </li>
                            <li className="list-inline-item">
                              <p className="text-muted fs-12 mb-0">
                                <i className="mdi mdi-map-marker"></i>{" "}
                                {jobVacancyListDetails.location}
                              </p>
                            </li>
                          </ul>
                        </div>

                        <div className="d-flex flex-wrap align-items-start gap-1">
                          <span className="badge bg-primary-subtle text-primary">
                            <i className="uil uil-money-bill"></i>{" "}
                            {jobVacancyListDetails.salary}
                          </span>
                          <span className="badge bg-success-subtle text-success">
                            <i className="uil uil-briefcase"></i>{" "}
                            {jobVacancyListDetails.experience}
                          </span>
                          {getAccessToken() && <span className="badge bg-danger-subtle text-danger">
                            <i className="uil uil-check-circle"></i>
                            {t("job.percentage")}: {jobVacancyListDetails.matchingPercentage}
                            %
                          </span>}
                        </div>
                      </div>
                      <div className="my-2">
                        <p className="text-truncate-3 text-muted fs-12 mb-0">
                          {jobVacancyListDetails.jobLongDescription}
                        </p>
                      </div>
                      <div className="mt-2 d-flex flex-wrap align-items-center gap-1">
                        <span
                          className={
                            jobVacancyListDetails.fullTime === true
                              ? "badge bg-success-subtle text-success fs-13 mt-1"
                              : jobVacancyListDetails.partTime === true
                                ? "badge bg-danger-subtle text-danger fs-13 mt-1"
                                : jobVacancyListDetails.freeLance === true
                                  ? "badge bg-primary-subtle text-primary fs-13 mt-1"
                                  : jobVacancyListDetails.internship === true
                                    ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                    : ""
                          }
                        >
                          {jobVacancyListDetails.timing}
                        </span>
                        {(jobVacancyListDetails.badges || []).map(
                          (badgeInner, key) => (
                            <span
                              className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                              key={key}
                            >
                              {badgeInner.badgeName}
                            </span>
                          )
                        )}
                        <span className="text-muted fs-12 mb-0 ms-2">
                          <i className="mdi mdi-history"></i>{" "}
                          {jobVacancyListDetails.jobPostTime}
                        </span>
                      </div>
                    </Link>
                    <div className="text-end">
                      <a href="#applyNow" onClick={openModal} className="btn btn-sm btn-primary">
                        {t("jobVacancy.applyNow")} <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
              <div className="favorite-icon">
                <Link to="#">
                  <i className="uil uil-heart-alt fs-18"></i>
                </Link>
              </div>
            </div>
          </div>
        ))}
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    {t("jobVacancy.applyJob")}
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    {t("jobVacancy.name")}
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder={t("course.enterYourName")}
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    {t("course.emailAddress")}
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder={t("course.enterYourEmail")}
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    {t("course.message")}
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder={t("course.enterMessage")}
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    {t("course.resumeUpload")}
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  {t("course.sendApplication")}
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>

      <Row>
        <Col lg={12} className="mt-4 pt-2">
          <nav aria-label="Page navigation example">
            <div className="pagination job-pagination mb-0 justify-content-center">
              <li onClick={(e) => decreaseSegement(1)} className="page-item disabled">
                <Link className="page-link" >
                  <i className="mdi mdi-chevron-double-left fs-15"></i>
                </Link>
              </li>
              {pagination()}
              <li onClick={(e) => incrementSegement(1)} className="page-item">
                <Link className="page-link" to="#">
                  <i className="mdi mdi-chevron-double-right fs-15"></i>
                </Link>
              </li>
            </div>
          </nav>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobVacancyList;
import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import useCourseGridStore from "../../stores/Courses/CourseGrid.store";
import CountryOptions from "../Common/SubSection/CountryOptions";
import JobType from "../Common/SubSection/JobType";

const CourseHeader = () => {
    const { t } = useTranslation();
    const { isGrid, setIsGrid } = useCourseGridStore(state => state);
    
    return (
        <React.Fragment>
            <form action="#">
                <Row className="g-2">
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-briefcase-alt"></i>
                            <Input
                                type="search"
                                className="form-control filter-input-box ps-5"
                                id="exampleFormControlInput1"
                                placeholder="Keywords, Course title"
                                style={{ marginTop: "-10px" }}
                            />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-briefcase"></i>
                            <JobType />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-clock"></i>
                            <Input
                                type="search"
                                className="form-control filter-input-box ps-5"
                                id="exampleFormControlInput1"
                                placeholder="Duration"
                                style={{ marginTop: "-10px" }}
                            />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link to="#" className="btn btn-primary w-100">
                            <i className="uil uil-search"></i> {t("course.search")}
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(false)} className={`btn btn-primary w-100 ${isGrid ? 'opacity-75': ''}`}>
                            <i className="uil uil-list-ul"></i> {t("course.listView")}
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(true)} className={`btn btn-primary w-100 ${!isGrid ? 'opacity-75': ''}`}>
                            <i className="uil uil-apps"></i> {t("course.gridView")}
                        </Link>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default CourseHeader;
import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Container, Row, Col } from "reactstrap";
import { Icon } from "@iconify/react";

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      id: 1,
      featureIcon: "uim-user-md",
      featureName: "Job Seeker Profiles"
    },
    {
      id: 2,
      featureIcon: "uim-telegram-alt",
      featureName: "Recruiter Profiles"
    },
    {
      id: 3,
      featureIcon: "uim-lock-access",
      featureName: "Privacy Policy"
    },
    {
      id: 4,
      featureIcon: "uim-list-ui-alt",
      featureName: "Job Listings"
    },
    {
      id: 5,
      featureIcon: "uim-bookmark",
      featureName: "Bookmark Jobs"
    },
    {
      id: 6,
      featureIcon: "uim-rocket",
      featureName: "Recommended Courses"
    },
    {
      id: 7,
      featureIcon: "uim-chart-pie",
      featureName: "Skill Analysis"
    },
    {
      id: 8,
      featureIcon: "uim-graph-bar",
      featureName: "Real Time Analytics"
    },
    {
      id: 9,
      featureIcon: "uim-refresh",
      featureName: "Quick Support"
    }
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="section-title text-center mb-5">
                <h3 className="title mb-4">{t("aboutUsPage.keyFeatures")}</h3>
                <p className="para-desc text-muted mx-auto">
                  {t("aboutUsPage.aboutUsDescription")}
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {features.map((featuresDetails, key) => (
              <Col lg={4} md={6} className="mt-4 pt-2" key={key}>
                <div className="about-feature p-3 d-flex align-items-center rounded-3">
                  <div className="featrue-icon flex-shrink-0">
                    <Icon
                      icon={featuresDetails.featureIcon}
                      className="text-primary"
                    />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6 className="fs-18 mb-0">
                      {featuresDetails.featureName}
                    </h6>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Features;
import React from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Col, Container, Row } from "reactstrap";
import useJobGridStore from "../../../stores/JobCards/JobGrid.store";
import JobGridHeader from "./JobHeader";
import JobVacancyGrid from "./JobVacancyGrid";
import JobVacancyList from "./JobVancancyList";
import Section from "./Section";
import Subscribe from "../../Home/subscribe/Subscribe";
import Footer from "../../Home/footer/Footer";

const Jobs = () => {
  const { t } = useTranslation();
  const { isGrid } = useJobGridStore(state => state);

  document.title = t("job.title");
  
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <JobGridHeader />
          <Row>
            {isGrid ? <JobVacancyGrid /> : <JobVacancyList />}
          </Row>
          {/* <Pagination /> */}
        </Container>
      </section>
      <div className="main-content">
        {/* <Subscribe /> */}
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Jobs;
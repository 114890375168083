import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  Form,
  NavItem,
  CardBody,
  Label,
  FormGroup
} from "reactstrap";

import classnames from "classnames";

//Images Import
import userImage2 from "../../assets/images/user/img-02.jpg";
import { Link } from "react-router-dom";
import { getProfile } from "../../services/User.service";
import useUserProfileDataStore from "../../stores/User/UserProfile.store";

const RightSideContent = () => {
  const { t } = useTranslation();

  const skillData = {
    name: '',
    proficencyLevel: 1
  };

  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = Array.from(new Array(currentYear - startYear + 1), (val, index) => startYear + index);
  const { userProfileData, setUserProfileData } = useUserProfileDataStore(state => state);
  const [activeTab, setActiveTab] = useState("1");
  const [profileData, setProfileData] = useState();

  const [start, setStart] = useState(currentYear);
  const [end, setEnd] = useState(currentYear);
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("");
  const [educations, setEducations] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [skills, setSkills] = useState([]);
  const [summary, setSummary] = useState("");
  const [objective, setSObjective] = useState("");
  const [techSkills, setTechSkills] = useState([]);
  const [softSkills, setSoftSkills] = useState([]);
  const [techSkillValue, setTechSkillValue] = useState("");
  const [softSkillValue, setSoftSkillValue] = useState("");

  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [state, setState] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [country, setCountry] = useState("");

  const educationData = {
    institutionName: "",
    degree: "",
    fieldOfStudy: "",
    start: new Date(),
    end: new Date(),
    startYear: currentYear,
    startMonth: new Date().getMonth(),
    endYear: currentYear,
    endMonth: new Date().getMonth()
  };

  const experienceData = {
    companyName: "",
    jobTitle: "",
    description: "",
    start: new Date(),
    end: new Date(),
    startYear: currentYear,
    startMonth: new Date().getMonth(),
    endYear: currentYear,
    endMonth: new Date().getMonth(),
  };

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const addTechSkill = () => {
    let techSkillTemp = skillData;
    techSkillTemp.name = techSkillValue;
    setTechSkills([...techSkills, ...[techSkillTemp]])
  }

  const addSoftSkill = () => {
    let techSkillTemp = skillData;
    techSkillTemp.name = softSkillValue;
    setSoftSkills([...softSkills, ...[techSkillTemp]])
  }

  const getProfileData = async () => {

    try {
      let response = await getProfile();
      if (response) {
        let responseData = response;
        setProfileData(responseData);
        let educationsTemp = [];
        let experiencesTemp = [];

        setUserProfileData(responseData);

        if (responseData.education && responseData.education.length > 0) {
          responseData.education.forEach(data => {
            educationsTemp.push({
              institutionName: data.institution_name,
              degree: data.degree,
              fieldOfStudy: data.field_of_study,
              start: data.start_date,
              end: data.end_date,
              startYear: new Date(data.start_date).getFullYear(),
              startMonth: new Date(data.start_date).getMonth(),
              endYear: new Date(data.end_date).getFullYear(),
              endMonth: new Date(data.end_date).getMonth()
            });
          });
          setEducations(educationsTemp);
        }
        else {
          setEducations([
            {
              institutionName: '',
              degree: '',
              fieldOfStudy: '',
              start: new Date(),
              end: new Date(),
              startYear: new Date().getFullYear(),
              startMonth: new Date().getMonth(),
              endYear: new Date().getFullYear(),
              endMonth: new Date().getMonth()
            }]);
        }

        if (responseData.experience && responseData.experience.length > 0) {
          responseData.experience.forEach(data => {
            experiencesTemp.push({
              companyName: data.company?.name,
              jobTitle: data.job_title,
              description: data.description,
              start: data.start_date,
              end: data.end_date,
              startYear: new Date(data.start_date).getFullYear(),
              startMonth: new Date(data.start_date).getMonth(),
              endYear: new Date(data.end_date).getFullYear(),
              endMonth: new Date(data.end_date).getMonth()
            });
          });

          setExperiences(experiencesTemp);
        }
        else {
          setExperiences([{
            companyName: '',
            jobTitle: '',
            description: '',
            start: new Date(),
            end: new Date(),
            startYear: new Date().getFullYear(),
            startMonth: new Date().getMonth(),
            endYear: new Date().getFullYear(),
            endMonth: new Date().getMonth()
          }]);
        }

        if (responseData.skills && responseData.skills.length > 0) {
          setSkills(responseData.skills);
        }

        setSummary(responseData.summary);
        setSObjective(responseData.summary);
        setFirstname(responseData.first_name);
        setLastname(responseData.last_name);

        return true;
      }
    }
    catch (err) {
      setEducations([educationData]);

      setExperiences([experienceData]);
      console.log(err);
    }
  };

  const addMoreExperience = (e) => {
    e.preventDefault();
    setExperiences([...experiences, ...[experienceData]]);
  }

  const deleteTechSkill = (e, index) => {
    e.preventDefault()
    let tempSkills = techSkills.filter((data, dataIndex) =>{
      return dataIndex != index;
    });
    setTechSkills(tempSkills);
  }

  const deleteSoftSkill = (e, index) => {
    e.preventDefault()
    let tempSkills = softSkills.filter((data, dataIndex) =>{
      return dataIndex != index;
    });
    setSoftSkills(tempSkills);
  }

  const addMoreEducation = (e) => {
    e.preventDefault();
    setEducations([...educations, ...[educationData]]);
  }

  const handleInputChange = (key, value, index) => {
    setEducations(educations => educations.map((education, i) => {
      if (i === index) {
        education[key] = value;
        return education;
      }
      else {
        return education;
      }
    }));
  };

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav
            className="profile-content-nav nav-pills border-bottom mb-4"
            id="pills-tab"
            role="tablist"
          >
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  tabChange("1");
                }}
                type="button"
              >
                {t("profile.overview")}
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  tabChange("2");
                }}
                type="button"
              >
                {t("profile.settings")}
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-18 fw-bold">{t("profile.summary")}</h5>
                  <p className={`text-muted ${summary ? 'mt-4' : ''}`}>
                    {summary ? summary : t("notAvailable")}
                  </p>
                </div>
                <div className="mt-4">
                  <h5 className="fs-18 fw-bold">{t("profile.careerObjectives")}</h5>
                  <p className={`text-muted ${objective ? 'mt-4' : ''}`}>
                    {objective ? objective : t("notAvailable")}
                  </p>
                </div>
                <div className="candidate-education-details mt-4">
                  <h6 className="fs-18 fw-bold mb-0">Education</h6>
                  {educations && educations[0] && !educations[0].degree && <span>{t("notAvailable")}</span>}
                  {educations && educations.length > 0 && educations[0].degree && educations.map((education, index) => {
                    return (<div className="candidate-education-content mt-4 d-flex">
                      <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        {education.degree ? education.degree.charAt(0).toUpperCase() : education.degree}
                      </div>
                      <div className="ms-4">
                        <h6 className="fs-16 mb-1">
                          {education.degree}
                        </h6>
                        <p className="mb-2 text-muted">
                          {education.institutionName} -  ({new Date(education.start).getFullYear()} - {new Date(education.end).getFullYear()})
                        </p>
                        <p className="text-muted">
                          {education.fieldOfStudy}
                        </p>
                      </div>
                    </div>)
                  })}
                </div>
                <div className="candidate-education-details mt-4">
                  <h6 className="fs-18 fw-bold mb-0">Experiences</h6>
                  {experiences && experiences[0] && !experiences[0].jobTitle && <span>{t("notAvailable")}</span>}
                  {experiences && experiences.length > 0 && experiences[0].jobTitle && experiences.map((experience, index) => {
                    return (
                      <div className="candidate-education-content mt-4 d-flex">
                        <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                          {" "}
                          {experience?.jobTitle ? experience?.jobTitle?.charAt(0).toUpperCase() : experience?.jobTitle}{" "}
                        </div>
                        <div className="ms-4">
                          <h6 className="fs-16 mb-1">
                            {experience.jobTitle}
                          </h6>
                          <p className="mb-2 text-muted">
                            {experience.companyName} - ({new Date(experience.start).getFullYear()} - {experience.end ? new Date(experience.end).getFullYear() : 'Present'})
                          </p>
                          <p className="text-muted">
                            {experience.description}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      {" "}
                      P{" "}
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1">Project Manager</h6>
                      <p className="mb-2 text-muted">
                        TalentBase Technology Pvt.Ltd - (2021 - 2023)
                      </p>
                      <p className="text-muted mb-0">
                        There are many variations of passages of available, but
                        the majority alteration in some form. As a highly
                        skilled and successfull product development and design
                        specialist with more than 4 Years of My experience.
                      </p>
                    </div>
                  </div> */}
                </div>
                <div className="mt-4">
                  <h5 className="fs-18 fw-bold">{t("profile.skillsExpertise")}</h5>
                </div>
                <div className="mt-0">
                  {skills && skills.length == 0 && <span>{t("notAvailable")}</span>}
                  {skills && skills.length > 0 && skills.map((skill, index) => {
                    return (<div className="d-flex justify-content-between gap-2 max-w-20r">
                      <span className="fs-14">{skill}</span>
                      <ul className="list-inline d-flex justify-content-center align-items-center min-w-100">
                        <li className="list-inline-item text-warning fs-19">
                          <i className="mdi mdi-star"></i>
                          <i className="mdi mdi-star"></i>
                          <i className="mdi mdi-star"></i>
                          <i className="mdi mdi-star"></i>
                          <i className="mdi mdi-star-half-full"></i>
                        </li>
                      </ul>
                    </div>)
                  })}

                </div>
                <div className="mt-4">
                  <h5 className="fs-18 fw-bold">{t("profile.spokenLanguages")}</h5>
                </div>
                <div className="mt-0 d-flex flex-wrap align-items-start gap-1">
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    English
                  </span>
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    German
                  </span>
                  <span className="badge fs-13 bg-success-subtle text-success mt-2">
                    French
                  </span>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <Form action="#">
                  <div>
                    <h5 className="fs-17 fw-semibold mb-3 mb-0">{t("profile.myAccount")}</h5>
                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        {t("profile.attachments")}
                      </h6>
                      <div className="d-flex flex-column gap-2 mb-3">
                        <div>
                          <Label htmlFor="resume" className="form-label">
                            {t("profile.resume")}
                          </Label>
                          <Input
                            id="resume"
                            type="file"
                            className="profile-img-file-input"
                          />
                        </div>
                        <div>
                          <div className="fs-14 mb-2 mb-0"></div>
                          <Label htmlFor="coverLetter" className="form-label">
                            {t("profile.coverLetter")}
                          </Label>
                          <Input
                            id="coverLetter"
                            type="file"
                            className="profile-img-file-input"
                          />
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">Profile</h6>
                      <div className="d-flex flex-column gap-3 mb-3">
                        <div className="mb-2">
                          <Label htmlFor="Summery" className="form-label">
                            {t("profile.summary")}
                          </Label>
                          <textarea
                            className="form-control"
                            placeholder={t("profile.enterSummery")}
                            name="Summary"
                            rows="3"
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="Objectives" className="form-label">
                            {t("profile.objectives")}
                          </Label>
                          <textarea
                            className="form-control"
                            placeholder={t("profile.enterObjectives")}
                            name="Objectives"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        {t("profile.basicInformation")}
                      </h6>
                      <div className="text-center">
                        <div className="mb-4 profile-user">
                          <img
                            src={userImage2}
                            className="rounded-circle img-thumbnail profile-img"
                            id="profile-img"
                            alt=""
                          />
                          <div className="p-0 rounded-circle profile-photo-edit">
                            <Input
                              id="profile-img-file-input"
                              type="file"
                              className="profile-img-file-input"
                            />
                            <Label
                              htmlFor="profile-img-file-input"
                              className="profile-photo-edit avatar-xs"
                            >
                              <i className="uil uil-edit cursor-pointer"></i>
                            </Label>
                          </div>
                        </div>
                      </div>

                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              {t("profile.firstName")}
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={profileData?.first_name}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="lastName" className="form-label">
                              {t("profile.lastName")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={profileData?.last_name}
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              for="nameControlInput"
                              className="form-label"
                            >
                              {t("profile.contactNumber")}
                            </Label>
                            <div className="row">
                              <div className="col-3">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nameControlInput"
                                  placeholder="(+94)"
                                  onChange={(e) => {
                                    setCountryCode(e.target.value);
                                  }}
                                />
                              </div>
                              <div className="col-9">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="nameControlInput"
                                  placeholder={t("profile.phoneNumber")}
                                  onChange={(e) => {
                                    setPhonenumber(e.target.value);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="email" className="form-label">
                              {t("profile.email")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="email"
                              value={profileData?.email}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="permanentAddress"
                              className="form-label"
                            >
                              {t("profile.permanentAddress")}
                            </Label>
                            <div className="d-flex flex-column gap-1">
                              <Input
                                type="text"
                                className="form-control"
                                id="permanentAddress"
                                placeholder={`${t("profile.addressLine")} 01`}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="permanentAddressL2"
                                placeholder={`${t("profile.addressLine")} 02`}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pCity"
                                placeholder={t("profile.city")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pState"
                                placeholder={t("profile.state")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pZipcode"
                                placeholder={t("profile.zipCode")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="pCountry"
                                placeholder={t("profile.country")}
                                value={profileData?.address}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="permanentAddress"
                              className="form-label"
                            >
                              {t("profile.currentAddress")}
                            </Label>
                            <div className="d-flex flex-column gap-1">
                              <Input
                                type="text"
                                className="form-control"
                                id="currentAddress"
                                placeholder={`${t("profile.addressLine")} 01`}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="currentAddressL2"
                                placeholder={`${t("profile.addressLine")} 02`}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cCity"
                                placeholder={t("profile.city")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cState"
                                placeholder={t("profile.state")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cZipcode"
                                placeholder={t("profile.zipCode")}
                                value={profileData?.address}
                              />
                              <Input
                                type="text"
                                className="form-control"
                                id="cCountry"
                                placeholder={t("profile.country")}
                                value={profileData?.address}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label
                              for="nameControlInput"
                              className="form-label"
                            >
                              {t("profile.dob")}
                            </Label>
                            <Input
                              type="date"
                              className="form-control"
                              id="nameControlInput"
                              placeholder="21 Aug 2001"
                              onChange={(e) => {
                                setDateOfBirth(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="languages" className="form-label">
                              {t("profile.languages")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="languages"
                              value={profileData?.email}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">{t("profile.socialMedia")}</h5>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="facebook" className="form-label">
                            Facebook
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="facebook"
                            to="https://www.facebook.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="twitter" className="form-label">
                            Twitter
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="twitter"
                            to="https://www.twitter.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="linkedin" className="form-label">
                            Linkedin
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="linkedin"
                            to="https://www.linkedin.com"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="whatsapp" className="form-label">
                            Whatsapp
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="whatsapp"
                            to="https://www.whatsapp.com"
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">Education</h5>
                    {educations && educations.length > 0 && educations.map((education, index) => {
                      return (
                        <div className="mb-3">
                          <h6 for="nameControlInput" className="form-label">
                            {t("education")} {index + 1}
                          </h6>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("university")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control mb-2"
                              id="universityInput"
                              placeholder={t("university")}
                              value={education.institutionName}
                              onChange={(e) => {
                                handleInputChange(
                                  "institutionName",
                                  e.target.value,
                                  index
                                );
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("degree")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control mb-2"
                              id="nameControlInput"
                              placeholder={t("degree")}
                              value={education.degree}
                              onChange={(e) => {
                                handleInputChange(
                                  "degree",
                                  e.target.value,
                                  index
                                );
                              }}
                            />
                          </FormGroup>
                          <FormGroup row className="mb-2">
                            <Label
                              for="yearRange"
                              className="form-label col-sm-12"
                            >
                              {t("startDate")}
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="startYear"
                                id="startYear"
                                value={new Date(education.start).getFullYear()}
                                onChange={(e) => {
                                  handleInputChange(
                                    "startYear",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>

                            <Col sm={6}>
                              <Input
                                type="select"
                                name="startMonth"
                                id="startMonth"
                                value={education.startMonth}
                                onChange={(e) => {
                                  handleInputChange(
                                    "startMonth",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month, i) => (
                                  <option key={i} value={i + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mb-2">
                            <Label
                              for="yearRange"
                              className="form-label col-sm-12"
                            >
                              {t("endDate")}
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="endYear"
                                id="endYear"
                                value={new Date(education.end).getFullYear()}
                                onChange={(e) => {
                                  handleInputChange(
                                    "endYear",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="endMonth"
                                id="endMonth"
                                value={education.endMonth}
                                onChange={(e) => {
                                  handleInputChange(
                                    "endMonth",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month, i) => (
                                  <option key={i} value={i + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("description")}
                            </Label>
                            <Input
                              type="textarea"
                              className="form-control"
                              id="nameControlInput"
                              placeholder={t("description")}
                            />
                          </FormGroup>
                        </div>
                      );
                    })}

                    <div className="text-end">
                      <button type="button" onClick={(e) => addMoreEducation(e)} className="btn btn-primary">
                        + {t("addMore")}
                      </button>
                    </div>

                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">{t("workExperience")}</h5>
                    { }

                    {experiences && experiences.length > 0 && experiences.map((experience, index) => {
                      return (
                        <div className="mb-3">
                          <h6 for="nameControlInput" className="form-label">
                            {t("experience")} {index + 1}
                          </h6>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("company")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control mb-2"
                              id="companyInput"
                              value={experience.companyName}
                              placeholder={t("company")}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("position")}
                            </Label>
                            <Input
                              type="text"
                              className="form-control mb-2"
                              id="positionInput"
                              value={experience.jobTitle}
                              placeholder={t("position")}
                              onChange={(e) => {
                                setPosition(e.target.value);
                              }}
                            />
                          </FormGroup>
                          <FormGroup row className="mb-2">
                            <Label
                              for="yearRange"
                              className="form-label col-sm-12"
                            >
                              {t("startDate")}
                            </Label>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="startYear"
                                id="startYear"
                                value={new Date(experience.start).getFullYear()}
                                onChange={(e) => setStart(e.target.value)}
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="startMonth"
                                id="startMonth"
                                value={experience.startMonth}
                                onChange={(e) => {
                                  handleInputChange(
                                    "startMonth",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month, i) => (
                                  <option key={i} value={i + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup row className="mb-2">
                            <Label for="yearRange" className="form-label col-sm-12">
                              {t("endDate")}
                            </Label>

                            <Col sm={6}>
                              <Input
                                type="select"
                                name="endYear"
                                id="endYear"
                                value={new Date(experience.end).getFullYear()}
                                onChange={(e) => setEnd(e.target.value)}
                              >
                                {years.map((year) => (
                                  <option key={year} value={year}>
                                    {year}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                            <Col sm={6}>
                              <Input
                                type="select"
                                name="startMonth"
                                id="startMonth"
                                value={experience.startMonth}
                                onChange={(e) => {
                                  handleInputChange(
                                    "startMonth",
                                    e.target.value,
                                    index
                                  );
                                }}
                              >
                                {[
                                  "January",
                                  "February",
                                  "March",
                                  "April",
                                  "May",
                                  "June",
                                  "July",
                                  "August",
                                  "September",
                                  "October",
                                  "November",
                                  "December",
                                ].map((month, i) => (
                                  <option key={i} value={i + 1}>
                                    {month}
                                  </option>
                                ))}
                              </Input>
                            </Col>
                          </FormGroup>
                          <FormGroup>
                            <Label for="universityInput" className="form-label">
                              {t("description")}
                            </Label>
                            <Input
                              type="textarea"
                              className="form-control"
                              id="nameControlInput"
                              value={experience.description}
                              placeholder={t("description")}
                            />
                          </FormGroup>
                        </div>
                      );
                    })}

                    <div className="text-end">
                      <button type="submit" onClick={(e) => addMoreExperience(e)} className="btn btn-primary">
                        + {t("addMore")}
                      </button>
                    </div>

                    <hr />
                    <hr />
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">
                      {t("profile.skillsExpertise")}
                    </h5>
                    {/* Tech Skills Section  */}
                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        {t("profile.technicalSkills")}
                      </h6>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="newSkill" className="form-label">
                              {t("profile.newSkill")}
                            </Label>
                            <div className="d-flex">
                              <Input
                                type="text"
                                className="form-control"
                                id="newSkill"
                                onChange={(e) => { setTechSkillValue(e.target.value) }}
                                placeholder={t("profile.addSkillPlaceholder")}
                              />
                              <button type="button" onClick={(e) => addTechSkill()} className="btn btn-primary ms-1">
                                {t("add")}
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        {techSkills && techSkills.length > 0 && <Row>
                          <Col lg={6}>
                            <h6 className="fs-14 fw-semibold mb-3">{t("profile.skill")}</h6>
                          </Col>
                          <Col lg={4}>
                            <h6 className="fs-14 fw-semibold mb-3">
                              {t("profile.proficiencyLevel")}
                            </h6>
                          </Col>
                          <Col lg={2}></Col>
                        </Row>}
                        {techSkills && techSkills.length > 0 && techSkills.map((skill, key) => {
                          return (<Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="skill-1"
                                  value={skill.name}
                                />
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  data-trigger
                                  name="prof-level"
                                  id="prof-level-01"
                                  aria-label="Proficiency Level"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <i onClick={(e) => deleteTechSkill(e, key)} className="uil uil-trash-alt fs-20 cursor-pointer"></i>
                            </Col>
                          </Row>)
                        })}
                      </div>
                    </div>
                    {/* Soft Skills Section  */}
                    <div>
                      <h6 className="fs-15 fw-semibold mb-3 mb-0">
                        {t("profile.softSkills")}
                      </h6>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="newSkill" className="form-label">
                              {t("profile.newSkill")}
                            </Label>
                            <div className="d-flex">
                              <Input
                                type="text"
                                className="form-control"
                                onChange={(e) => { setSoftSkillValue(e.target.value) }}
                                id="newSoftSkill"
                                placeholder={t("profile.newSkillPlaceholder")}
                              />
                              <button type="button" onClick={(e) => addSoftSkill()} className="btn btn-primary ms-1">
                                {t("add")}
                              </button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-4">
                        {softSkills && softSkills.length > 0 &&
                          <Row>
                            <Col lg={6}>
                              <h6 className="fs-14 fw-semibold mb-3">{t("profile.skill")}</h6>
                            </Col>
                            <Col lg={4}>
                              <h6 className="fs-14 fw-semibold mb-3">
                                {t("profile.proficiencyLevel")}
                              </h6>
                            </Col>
                            <Col lg={2}></Col>
                          </Row>}
                        {softSkills && softSkills.length > 0 && softSkills.map((skill, key) => {
                          return (<Row>
                            <Col lg={6}>
                              <div className="mb-3">
                                <Input
                                  type="text"
                                  value={skill.name}
                                  className="form-control"
                                  id="skill-s-1"
                                />
                              </div>
                            </Col>

                            <Col lg={4}>
                              <div className="mb-3">
                                <select
                                  className="form-select"
                                  data-trigger
                                  name="prof-level"
                                  id="prof-level-01"
                                  aria-label="Proficiency Level"
                                >
                                  <option value="1">1</option>
                                  <option value="2">2</option>
                                  <option value="3">3</option>
                                  <option value="4">4</option>
                                  <option value="5">5</option>
                                </select>
                              </div>
                            </Col>
                            <Col lg={2}>
                              <i onClick={(e) => deleteSoftSkill(e, key)} className="uil uil-trash-alt fs-20 cursor-pointer"></i>
                            </Col>
                          </Row>)
                        })}
                      </div>
                    </div>
                    <hr />
                  </div>

                  <div className="mt-4 text-end">
                    <Link to="#" className="btn btn-primary">
                      {t("profile.update")}
                    </Link>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;

import axios from "axios";
import { getAccessToken, isTokenExpired, refreshToken } from "./User.service";


export const baseApi = Object.freeze({
    get: apiGet,
    getWithParams: apiGetWithParams,
    post: apiPost,
    put: apiPut,
    patch: apiPatch,
    delete: apiDelete,
    postCustomHeaders: apiPostCustomHeaders,
    apiPostWithoutToken: apiPostWithoutToken,
    apiGetWithoutToken: apiGetWithoutToken
});

export const axiosBaseApiClient = axios.create({
});

export const axiosBaseApiClientWithoutToken = axios.create({
});

axiosBaseApiClient.interceptors.request.use(async (req) => {
    if(isTokenExpired()){
        await refreshToken();
    }
    return req;
})


async function apiGet(path) {
    try {
        let options =
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        };
        const response = await axiosBaseApiClient.get(path, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiGetWithParams(path, params) {
    try {
        const response = await axiosBaseApiClient.get(path, {
            params: params,
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        });

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

export async function apiPost(path, data) {
    try {
        let options =
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        };
        const response = await axiosBaseApiClient.post(path, data, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiGetWithoutToken(path, params) {
    try {
        let options =
        {
            timeout: 10000
        };
        const response = await axiosBaseApiClientWithoutToken.get(path, params, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

export async function apiPostWithoutToken(path, data) {
    try {
        let options =
        {
            timeout: 10000
        };
        const response = await axiosBaseApiClientWithoutToken.post(path, data, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiPut(path, data) {
    try {
        let options =
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        };
        const response = await axiosBaseApiClient.put(path, data, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiPatch(path, data) {
    try {
        let options =
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        };
        const response = await axiosBaseApiClient.patch(path, data, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiDelete(path) {
    try {
        let options =
        {
            headers: {
                Authorization: `Bearer ${getAccessToken()}`
            },
            timeout: 10000
        };
        const response = await axiosBaseApiClient.delete(path, options);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

async function apiPostCustomHeaders(path, data, headers) {
    try {

        headers['Authorization'] = `Bearer ${getAccessToken()}`;

        const response = await axiosBaseApiClient.post(path, data, headers);

        return response.data;
    } catch (err) {
        throw err?.response?.data || err?.request || err?.message;
    }
}

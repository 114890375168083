import React from "react";
import { Container, Row, Col } from "reactstrap";

const Terms = () => {
  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={12}>
              <div className="section-title text-center">
                <h2 className="title mb-4">Terms and Conditions</h2>
                <p className="text-muted">
                  Please read these Terms and Conditions carefully before using
                  our website or subscribing to our services.
                </p>
              </div>
              <div className="terms-content">
                <h4>1. Introduction</h4>
                <p>
                  Welcome to TalentBase. These Terms and Conditions govern your use of our website, subscription services, and the purchase of our monthly subscription plans. By subscribing to our services, you agree to comply with these terms. Please read them carefully before proceeding.
                </p>

                <h4>2. Refund Policy</h4>
                <p>
                  Thank you for subscribing to TalentBase. We value your satisfaction and strive to provide the best services possible. If, for any reason, you are not satisfied with your subscription, we are here to help.
                </p>
                <p><strong>Refund Period:</strong> We offer a 14-day refund period from the date of your initial subscription. If you are not satisfied with our service within this period, you can request a full refund.</p>
                <p><strong>Eligibility for Refund:</strong> To be eligible for a refund, you must submit your request within 14 days of your initial subscription. After this period, no refunds will be provided for the current billing cycle.</p>
                <p><strong>Refund Process:</strong> Once we receive your refund request, we will process it within 7 business days and issue the refund to the original payment method. Refund will be given to the payment initiated media itself on the website. Please note that the refund does not include any transaction fees incurred during the subscription process.</p>

                {/* Refund policy for one-time job advertisement payment */}
                <p><strong>Refund Policy for One-Time Job Posting Payment:</strong> If you purchase a one-time job advertisement for RS 1000 (30 days), you are eligible for a refund within 14 days of payment, regardless of whether the job advertisement has gone live or not. To request a refund, please contact us within 14 days of payment, and we will process the refund to the original payment method.</p>

                <h4>3. Subscription Terms</h4>
                <p>
                  TalentBase offers monthly subscription plans for access to our premium features. The subscription will automatically renew each month unless cancelled before the next billing cycle.
                </p>
                <p><strong>Billing Cycle:</strong> The subscription fee will be billed at the start of each monthly billing cycle. You will be charged automatically unless you cancel your subscription before the renewal date.</p>
                <p><strong>Cancellation:</strong> You can cancel your subscription at any time before the next billing cycle. Upon cancellation, you will retain access to the premium features until the end of the current billing period, but no further charges will be made.</p>

                <h4>4. Privacy Policy</h4>
                <p>
                  At TalentBase, we are committed to protecting the privacy and security of our customers' personal information. This Privacy Policy outlines how we collect, use, and safeguard your information when you subscribe to our services.
                </p>
                <p><strong>Information We Collect:</strong> We collect personal information such as your name, email address, payment details, and subscription preferences when you sign up for a subscription. We may also collect information related to your use of the service.</p>
                <p><strong>Use of Information:</strong> We use your information to process and manage your subscription, communicate with you about your account, and improve our services.</p>

                <h4>5. Terms of Service</h4>
                <p>
                  By subscribing to TalentBase, you agree to the following terms:
                </p>
                <ul>
                  <li>You must be at least 18 years old to subscribe to our services.</li>
                  <li>Your subscription will automatically renew unless cancelled before the renewal date.</li>
                  <li>You are responsible for maintaining the confidentiality of your account information.</li>
                  <li>You agree to use our services only for lawful purposes and not to violate the rights of others.</li>
                </ul>

                <h4>6. Contact Us</h4>
                <p>
                  If you have any questions or concerns regarding our Terms and Conditions, Refund Policy, Privacy Policy, or Subscription Services, please contact us:
                </p>
                <p>Email: support@talentbase.com</p>
                <p>Phone: 0812219879</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Terms;

import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useGoogleLogin } from '@react-oauth/google';
import { Container, Card, Col, Input, Row, CardBody, Modal, ModalBody, Label, Alert } from "reactstrap";
import { useNavigate } from "react-router-dom";

import lightLogo from "../../../logo.svg";
import darkLogo from "../../../logo.svg";

import linkedInLogo from "../../../assets/images/social-media/linkedIn.png";
import fbLogo from "../../../assets/images/social-media/facebook.svg";
import googleLogo from "../../../assets/images/social-media/google.svg";

import signUpImage from "../../../assets/images/illustrations/sign-up.svg";
import { Form } from "react-bootstrap";
import ProfileInformation from "./wizard/ProfileInformation";
import ProfileEducation from "./wizard/ProfileEducation";
import ProfileWorkExperience from "./wizard/ProfileWorkExperience";
import ProfileSkillInterest from "./wizard/ProfileSkillsInterest";
import ProfileIndustry from "./wizard/ProfileIndustry";
import { validateEmail } from "../../../utils/common";
import { signup, setAccessToken, setLoginType, setRefreshToken } from "../../../services/User.service";
import { useLoaderStore } from "../../../stores/Loader.store";


const SignUp = () => {
  const { t } = useTranslation();
  document.title = t("signUpPage.title");

  const { isLoading, setIsLoading } = useLoaderStore(state => state);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isInvalid, setIsInvalid] = useState({
    isEmailValid: undefined,
    isPasswordValid: undefined
  });
  const [isAgree, setIsAgree] = useState(false);

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [isOpenSuccessAlert, setIsOpenSuccessAlert] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [profileModal, setProfileModal] = useState(false);
  const openProfileModal = () => setProfileModal(!profileModal);

  const [profileEducationModal, setProfileEducationModal] = useState(false);
  const openProfileEducationModal = () => setProfileEducationModal(!profileEducationModal);

  const [profileExperienceModal, setProfileExperienceModal] = useState(false);
  const openProfileExperienceModal = () => setProfileExperienceModal(!profileExperienceModal);

  const [profileSkillInterestModal, setProfileSkillInterestModal] = useState(false);
  const openProfileInterestModal = () => setProfileSkillInterestModal(!profileSkillInterestModal);

  const [profileSIndustryModal, setProfileIndustryModal] = useState(false);
  const openProfileIndustryModal = () => setProfileIndustryModal(!profileSIndustryModal);


  const navigate = useNavigate();

  const onSubmitEvent = (e) => {
    e.preventDefault();
    console.log('dats');
    openProfileModal();
    navigate("#profileForm");
  }

  const openEducationEvent = () => {
    openProfileModal();
    openProfileEducationModal();
  }

  const openExperienceEvent = () => {
    openProfileEducationModal();
    openProfileExperienceModal();
  }

  const openSkillInterestEvent = () => {
    openProfileExperienceModal();
    openProfileInterestModal();
  }

  const openIndustryEvent = () => {
    openProfileInterestModal();
    openProfileIndustryModal();
  }

  const handleDismiss = () => {
    setIsOpenAlert(false);
  };

  const redirectUriData = `${process.env.REACT_APP_API_HOST}/api/v1/auth/login/google/`;

  const scopeData = [
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile'
  ].join(' ');

  const googleSignup = useGoogleLogin(
    {
      flow: "auth-code",
      scope: scopeData,
      prompt: 'select_account',
      ux_mode: "redirect",
      redirect_uri: redirectUriData
    });


  const signUpLocal = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setIsOpenAlert(false);

    if (!validateEmail(email)) {
      setIsInvalid({
        isEmailValid: false
      });
      setErrorMessage(t("signUpPage.invalidFormat"));
      setIsOpenAlert(true);
      return;
    }

    if (!passwordStrength()) {
      return;
    }

    if (!isAgree) {
      setErrorMessage(t("signUpPage.pleaseAgree"));
      setIsOpenAlert(true);
      return;
    }

    let data = {
      "username": email,
      "password": password,
      "email": email,
      "user_type": "jobseeker"
    };

    setIsLoading(true);

    try {
      let response = await signup(data);
      if (response) {
        let responseData = response;
        setAccessToken(responseData.access);
        setRefreshToken(responseData.refresh);
        setLoginType('local');
        onSubmitEvent(e);
      }
    }
    catch (err) {
      if (err.detail) {
        setErrorMessage(err.detail);
        setIsOpenAlert(true);
        return;
      }
      if (err.username) {
        setErrorMessage(err.username);
        setIsOpenAlert(true);
        return;
      }
      setErrorMessage(t("signUpPage.invalidUsernameEmail"));
      setIsOpenAlert(true);
    }
    finally {
      setIsLoading(false);
    }

  }

  const passwordStrength = () => {
    let pattern = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
    );
    if (!pattern.test(password)) {
      setErrorMessage(t("signUpPage.weakPassword"));
      setIsOpenAlert(true);
      return false;
    }
    const strength = {
      1: "very Weak",
      2: "Weak",
      3: "Meduim",
      4: "Strong",
    };

    let count = 0;
    let regex1 = /[a-z]/;
    if (regex1.test(password)) count++;
    let regex2 = /[A-Z]/;
    if (regex2.test(password)) count++;
    let regex3 = /[\d]/;
    if (regex3.test(password)) count++;
    let regex4 = /[!@#$%^&*.?]/;
    if (regex4.test(password)) count++;

    if (count == 4) {
      setIsOpenSuccessAlert(true);
      setSuccessMessage(t("signUpPage.strongPassword"));
    }
    else {
      setErrorMessage(true);
      errorMessage(t("signUpPage.mediumPassword"));
      return false;
    }
    return true;
  }

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                              />
                              <img
                                height="200"
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                              />
                            </Link>
                            <div>
                              <img
                                src={signUpImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content px-5 py-3 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>{t("signUpPage.letGetStarted")}</h5>
                                <p className="text-white-70">
                                  {t("signUpPage.signUpAccess")}
                                </p>
                              </div>
                              {errorMessage && isOpenAlert && (
                                <Alert color="danger" toggle={handleDismiss} className="mt-3 text-danger">
                                  <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                  {errorMessage}
                                </Alert>
                              )}

                              {/* {errorMessage && isOpenAlert && (
                                  <Alert color="success" toggle={handleDismiss} className="mt-3 text-danger">
                                    <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                    {errorMessage}
                                  </Alert>
                                )} */}

                              {isOpenSuccessAlert && (
                                <Alert color="success" toggle={handleDismiss} className="mt-3 text-success">
                                  <i className="uil uil-exclamation-circle fs-5 me-2"></i>
                                  {successMessage}
                                </Alert>
                              )}
                              <Form className="auth-form">
                                <div className="mb-3">
                                  <label
                                    htmlFor="usernameInput"
                                    className="form-label"
                                  >
                                    {t("signUpPage.userEmail")}
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-user"></i>
                                    </span>
                                    <Input
                                      type="text"
                                      className="form-control"
                                      id="emailInput"
                                      placeholder={t("signUpPage.enterEmail")}
                                      onChange={(e) => { setEmail(e.target.value) }}
                                      invalid={isInvalid.isEmailValid == false}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    Password
                                  </label>
                                  <div className="input-group input-group--login">
                                    <span className="input-group-text border-0">
                                      <i className="uil uil-lock"></i>
                                    </span>
                                    <Input
                                      type="password"
                                      className="form-control"
                                      id="passwordInput"
                                      onChange={(e) => { setPassword(e.target.value) }}
                                      placeholder={t("signUpPage.enterPassword")}
                                      required
                                    />
                                  </div>
                                </div>

                                <div className="mb-4">
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      onChange={(e) => { setIsAgree(e.target.checked) }}
                                      id="flexCheckDefault"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      {t("signUpPage.agreeThe")}{" "}
                                      <Link
                                        to="#"
                                        className="text-white text-decoration-underline"
                                      >
                                        {t("signUpPage.termsConditions")}
                                      </Link>
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="button"
                                    onClick={(e) => signUpLocal(e)}
                                    className="btn btn-white btn-hover w-100"
                                  >
                                    {t("signUpPage.signup")}
                                  </button>
                                </div>
                                <div className="mt-4 text-center">
                                  <p className="mb-0">{t("signUpPage.signUpOr")}</p>
                                </div>
                                <ul className="candidate-detail-social-menu list-inline mb-0 mt-3 d-flex justify-content-center">
                                  {/*  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={linkedInLogo}
                                        height="24"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <Link
                                      to="#"
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img src={fbLogo} height="35" alt="" />
                                    </Link>
                                  </li>*/}
                                  <li className="list-inline-item">
                                    <Link
                                      onClick={(e) => googleSignup()}
                                      className="social-link rounded-3 d-flex align-items-center justify-content-center"
                                    >
                                      <img
                                        src={googleLogo}
                                        height="22"
                                        alt=""
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </Form>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  {t("signUpPage.alreadyMember")}{" "}
                                  <Link
                                    to="/signin"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    {t("signUpPage.signIn")}{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="profileForm"
        tabIndex="-1"
        aria-labelledby="profileForm"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <ProfileInformation data={{ email: email }} modal={profileModal} openModal={openProfileModal} callBackSubmit={openEducationEvent} />
        </div>

        <div className="modal-dialog modal-dialog-centered">
          <ProfileEducation modal={profileEducationModal} openModal={openProfileEducationModal} callBackSubmit={openExperienceEvent} />
        </div>

        <div className="modal-dialog modal-dialog-centered">
          <ProfileWorkExperience modal={profileExperienceModal} openModal={openProfileExperienceModal} callBackSubmit={openSkillInterestEvent} />
        </div>

        <div className="modal-dialog modal-dialog-centered">
          <ProfileSkillInterest modal={profileSkillInterestModal} openModal={openSkillInterestEvent} callBackSubmit={openIndustryEvent} />
        </div>

        <div className="modal-dialog modal-dialog-centered">
          <ProfileIndustry modal={profileSIndustryModal} openModal={openProfileIndustryModal} callBackSubmit={(e) => navigate('/profile')} />
        </div>

      </div>
    </React.Fragment>
  );
};

export default SignUp;
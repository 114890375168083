import { create } from "zustand";

const userProfileDataStore = (set, get) => ({
  userProfileData: [],
  setUserProfileData: (userProfileData) => {
    set(state => ({
      ...state,
      userProfileData
    }));
  },
});

const useUserProfileDataStore = create(userProfileDataStore);

export default useUserProfileDataStore;
import React from "react";
import { Container, Row, Col, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import { Link } from 'react-router-dom';  // Import Link for navigation

const Pricing = () => {
  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={12}>
              <div className="section-title text-center">
                <h2 className="title mb-4">Pricing</h2>
                <p className="text-muted">
                  Choose the plan that works best for you. We offer flexible options to meet your needs.
                </p>
              </div>

              {/* Inline Styles */}
              <style>
                {`
                  .refund-policy-link {
                    color: red;
                    text-decoration: none;
                  }
                  .refund-policy-link:hover {
                    text-decoration: underline;
                  }
                `}
              </style>

              {/* Job Posting Section */}
              <div className="pricing-section">
                <h3 className="text-center mb-4">Job Posting</h3>
                <Row className="g-4">
                  {/* Free Plan for Job Posting */}
                  <Col md={6}>
                    <Card className="text-center">
                      <CardBody>
                        <CardTitle tag="h4" className="mb-3">Free Plan</CardTitle>
                        <CardText className="text-muted">One job posting for 7 days</CardText>
                        <h3 className="mb-3">Free</h3>
                        <p className="mb-4">This plan allows you to post one job listing for 7 days, absolutely free of charge.</p>
                        {/* Link to "Sign Up Employer" page */}
                        <Link to="/sign-up-employer">
                          <Button color="primary">Get Started</Button>
                        </Link>
                        {/* Link to Terms page for refund policy */}
                        <p className="mt-3">
                          <Link to="/terms" className="refund-policy-link">Read about refund policy</Link>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* Paid Plan for Job Posting */}
                  <Col md={6}>
                    <Card className="text-center">
                      <CardBody>
                        <CardTitle tag="h4" className="mb-3">Paid Plan</CardTitle>
                        <CardText className="text-muted">One job posting for 30 days</CardText>
                        <h3 className="mb-3">RS 1000</h3>
                        <p className="mb-4">This plan allows you to post one job listing for 30 days, with more exposure.</p>
                        {/* Link to "Sign Up Employer" page */}
                        <Link to="/sign-up-employer">
                          <Button color="primary">Get Started</Button>
                        </Link>
                        {/* Link to Terms page for refund policy */}
                        <p className="mt-3">
                          <Link to="/terms" className="refund-policy-link">Read about refund policy</Link>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>

              {/* Analyst Section */}
              <div className="pricing-section mt-5">
                <h3 className="text-center mb-4">Analyst</h3>
                <Row className="g-4">
                  {/* Free Trial for Analyst */}
                  <Col md={6}>
                    <Card className="text-center">
                      <CardBody>
                        <CardTitle tag="h4" className="mb-3">Free Trial</CardTitle>
                        <CardText className="text-muted">7 days free trial</CardText>
                        <h3 className="mb-3">Free</h3>
                        <p className="mb-4">Try out our Analyst service for 7 days at no charge. Access all premium features during the trial period.</p>
                        <Button color="primary">Start Free Trial</Button>
                        {/* Link to Terms page for refund policy */}
                        <p className="mt-3">
                          <Link to="/terms" className="refund-policy-link">Read about refund policy</Link>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>

                  {/* Paid Plan for Analyst */}
                  <Col md={6}>
                    <Card className="text-center">
                      <CardBody>
                        <CardTitle tag="h4" className="mb-3">Subscription Plan</CardTitle>
                        <CardText className="text-muted">Monthly subscription</CardText>
                        <h3 className="mb-3">RS 5000</h3>
                        <p className="mb-4">This plan grants you full access to all Analyst features with a monthly subscription of Rs 5000.</p>
                        <Button color="primary">Subscribe Now</Button>
                        {/* Link to Terms page for refund policy */}
                        <p className="mt-3">
                          <Link to="/terms" className="refund-policy-link">Read about refund policy</Link>
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Pricing;

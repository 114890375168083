import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Input, Modal, ModalBody, Label, Col, FormGroup } from "reactstrap";
import { Form } from "react-bootstrap";
import { updateProfile } from "../../../../services/User.service";

const ProfileWorkExperience = ({ modal = false, openModal, callBackSubmit }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const years = Array.from(new Array(currentYear - startYear + 1), (val, index) => startYear + index);

  const experienceData = {
    companyName: "",
    jobTitle: "",
    description: "",
    startYear: currentYear,
    startMonth: new Date().getMonth(),
    endYear: currentYear,
    endMonth: new Date().getMonth(),
  };

  const [experiences, setExperiences] = useState([experienceData]);

  const onSubmitEvent = async (e) => {
    e.preventDefault();
    await updateWorkExperience();
    callBackSubmit()
  }

  const closeModal = (e) => {
    e.preventDefault();
    callBackSubmit()
  }

  const updateWorkExperience = async () => {
    try {
      let data = [];

      experiences.map((experience) => {
        data.push({
          company: {
            name: experience.companyName
          },
          job_title: experience.jobTitle,
          description: experience.description,
          start_date: `${experience.startYear}-${("0" + experience.startMonth).slice(-2)}-01`,
          end_date: `${experience.endYear}-${("0" + experience.endMonth).slice(-2)}-01`
        });
      });

      let requestData = {
        experience: data
      };

      return await updateProfile(requestData);

    }
    catch (err) {
      console.log(err);
    }
  };

  const addMore = (e) => {
    e.preventDefault();
    setExperiences([...experiences, ...[experienceData]]);
  }

  const handleInputChange = (key, value, index) => {
    setExperiences(experiences => experiences.map((experience, i) => {
      if (i === index) {
        experience[key] = value;
        return experience;
      }
      else {
        return experience;
      }
    }));
  };

  return (
    <div className="modal-dialog modal-dialog-centered">
      <Modal isOpen={modal} toggle={openModal} centered>
        <ModalBody className="modal-body p-5">
          <div className="text-center mb-4">
            <h5 className="modal-title" id="staticBackdropLabel">
              {t("profileWorkExperience.title")}
            </h5>
            <div>{t("profileWorkExperience.addWork")}</div>
          </div>
          <div className="position-absolute end-0 top-0 p-3">
            <button
              type="button"
              onClick={openModal}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <Form onSubmit={onSubmitEvent}>
            {experiences && experiences.length > 0 && experiences.map((experience, index) => {
              return (
                <div className="mb-3">
                  <h6 for="nameControlInput" className="form-label">
                    {t("profileWorkExperience.experience")} {index + 1}
                  </h6>
                  <FormGroup>
                    <Label for="companyInput" className="form-label">
                      {t("profileWorkExperience.company")}
                    </Label>
                    <Input
                      type="text"
                      className="form-control mb-2"
                      id="companyInput"
                      placeholder={t("profileWorkExperience.company")}
                      value={experience.companyName}
                      onChange={(e) => {
                        handleInputChange(
                          "companyName",
                          e.target.value,
                          index
                        );
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="positionInput" className="form-label">
                      {t("profileWorkExperience.position")}
                    </Label>
                    <Input
                      type="text"
                      className="form-control mb-2"
                      id="positionInput"
                      placeholder={t("profileWorkExperience.position")}
                      value={experience.jobTitle}
                      onChange={(e) => {
                        handleInputChange(
                          "jobTitle",
                          e.target.value,
                          index
                        );
                      }}
                    />
                  </FormGroup>
                  <FormGroup row className="mb-2">
                    <Label for="yearRange" className="form-label col-sm-12">
                      {t("profileWorkExperience.startDate")}
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="startYear"
                        id="startYear"
                        value={experience.starYear}
                        onChange={(e) => {
                          handleInputChange(
                            "startYear",
                            e.target.value,
                            index
                          );
                        }}
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="startMonth"
                        id="startMonth"
                        value={experience.startMonth}
                        onChange={(e) => {
                          handleInputChange(
                            "startMonth",
                            e.target.value,
                            index
                          );
                        }}
                      >
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mb-2">
                    <Label for="yearRange" className="form-label col-sm-12">
                      {t("profileWorkExperience.endDate")}
                    </Label>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="endYear"
                        id="endYear"
                        value={experience.endYear}
                        onChange={(e) => {
                          handleInputChange(
                            "endYear",
                            e.target.value,
                            index
                          );
                        }}
                      >
                        {years.map((year) => (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        ))}
                      </Input>
                    </Col>
                    <Col sm={6}>
                      <Input
                        type="select"
                        name="endMonth"
                        id="endMonth"
                        value={experience.endMonth}
                        onChange={(e) => {
                          handleInputChange(
                            "endMonth",
                            e.target.value,
                            index
                          );
                        }}
                      >
                        {[
                          "January",
                          "February",
                          "March",
                          "April",
                          "May",
                          "June",
                          "July",
                          "August",
                          "September",
                          "October",
                          "November",
                          "December",
                        ].map((month, i) => (
                          <option key={i} value={i + 1}>
                            {month}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label for="universityInput" className="form-label">
                      {t("profileWorkExperience.description")}
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="nameControlInput"
                      placeholder={t("profileWorkExperience.description")}
                      value={experience.description}
                      onChange={(e) => {
                        handleInputChange(
                          "description",
                          e.target.value,
                          index
                        );
                      }}
                    />
                  </FormGroup>
                </div>
              )
            })}
            {/* <h6 for="nameControlInput" className="form-label">
              Experience 01
            </h6>
            <FormGroup>
              <Label for="universityInput" className="form-label">
                Company
              </Label>
              <Input
                type="text"
                className="form-control mb-2"
                id="companyInput"
                placeholder="Company"
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="universityInput" className="form-label">
                Position
              </Label>
              <Input
                type="text"
                className="form-control mb-2"
                id="positionInput"
                placeholder="Position"
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
              />
            </FormGroup> */}
            {/* <FormGroup row className="mb-2">
              <Label for="yearRange" className="form-label col-sm-12">
                Start Date
              </Label>
              <Col sm={6}>
                <Input
                  type="select"
                  name="startYear"
                  id="startYear"
                  value={start}
                  onChange={(e) => setStart(e.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </Col> */}
            {/* <Col sm={6}>
                    <Input
                      type="select"
                      name="startMonth"
                      id="startMonth"
                      value={startMonth}
                      onChange={(e) => setStartMonth(e.target.value)}
                    >
                      {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ].map((month, i) => (
                        <option key={i} value={i + 1}>
                          {month}
                        </option>
                      ))}
                    </Input>
                  </Col> */}
            {/* </FormGroup>
            <FormGroup row className="mb-2">
              <Label for="yearRange" className="form-label col-sm-12">
                End Date
              </Label>
              <Col sm={6}>
                <Input
                  type="select"
                  name="endYear"
                  id="endYear"
                  value={end}
                  onChange={(e) => setEnd(e.target.value)}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Input>
              </Col> */}
            {/* <Col sm={6}>
                    <Input
                      type="select"
                      name="endMonth"
                      id="endMonth"
                      value={endMonth}
                      onChange={(e) => setEndMonth(e.target.value)}
                    >
                      {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ].map((month, i) => (
                        <option key={i} value={i + 1}>
                          {month}
                        </option>
                      ))}
                    </Input>
                  </Col> */}
            {/* </FormGroup>
            <FormGroup>
              <Label for="universityInput" className="form-label">
                Description
              </Label>
              <Input
                type="textarea"
                className="form-control"
                id="nameControlInput"
                placeholder="Description"
              />
            </FormGroup> */}

            <div>
              <button onClick={(e) => addMore(e)} type="submit" className="btn btn-primary w-100">
                + {t("profileWorkExperience.addMore")}
              </button>
            </div>

            <hr />

            <div className="d-flex">
              <button
                type="button"
                onClick={e => closeModal(e)}
                className="btn btn-outline-primary w-100 me-2"
              >
                {t("profileWorkExperience.addLater")}
              </button>

              <button type="submit" className="btn btn-primary w-100">
              {t("profileWorkExperience.next")}
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div >
  );
};

export default ProfileWorkExperience;
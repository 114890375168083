import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, ModalBody, Input, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { fetchJobs } from "../../../services/Job.service";
import {getAccessToken} from "../../../services/User.service";

import companyImage from "../../../assets/images/default-image.jpg";

import jobImage1 from "../../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../../assets/images/featured-job/img-07.png";
import jobImage8 from "../../../assets/images/featured-job/img-08.png";
import jobImage9 from "../../../assets/images/featured-job/img-09.png";

const JobVacancyGrid = () => {
  const { t } = useTranslation();
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [jobVacancyList, setJobVacancyList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSegement, setPageSegement] = useState(1);

  const TotalSegment = 4;
  const PageCount = 10;


  useEffect(() => {
    getJobList();
  }, []);

  useEffect(() => {
    if (page) {
      getJobList();
    }
  }, [page]);

  const getJobList = async () => {
    try {
      let paramData = { page: page };
      let response = await fetchJobs(paramData);
      if (response) {

        let tempData = [];

        setTotalPage(response.count / PageCount);

        response.results.forEach((data) => {
          tempData.push({
            id: data.id,
            companyImg: data.url,
            jobDescription: data.title,
            companyName: data.company,
            location: data.location,
            jobPostTime: data.time_since,
            fullTime: data.job_type == 'Full Time' ? true : false,
            timing: data.job_type,
            addclassNameBookmark: false,
            badges: [],
            experience: data.experience_needed,
            salary: `${data.salary_currency}${data.salary}/Month`,
            matchingPercentage: 80,
            jobLongDescription: data.description
          });
        });

        setJobVacancyList(tempData);
        return true;

      }
    }
    catch (err) {
      console.log(err);
    }
  }


  const changePage = (pageCount) => {
    setPage(pageCount);
  }

  const incrementSegement = (page) => {
    let pageSegementData = pageSegement + page;
    setPageSegement(pageSegementData);
    setPage(((pageSegementData - 1) * TotalSegment) + 1);
  }

  const decreaseSegement = (page) => {
    let pageSegementData = pageSegement - page;
    setPageSegement(pageSegementData);
    setPage(((pageSegementData - 1) * TotalSegment) + TotalSegment);
  }

  const pagination = () => {
    let pageTag = [];
    let startIterate = ((pageSegement - 1) * TotalSegment) + 1;
    let iterate = pageSegement * TotalSegment;
    for (let i = (startIterate - 1); i < iterate; i++) {
      pageTag.push(<li onClick={(e) => changePage(i + 1)} className={`page-item ${page == (i + 1) ? "active" : ""}`}>
        <Link className="page-link" to="#">
          {i + 1}
        </Link>
      </li>);
    }

    return pageTag;
  }

  // const jobVacancy2 = [
  //   {
  //     id: 1,
  //     companyImg: jobImage1,
  //     jobDescription: "Magento Developer",
  //     experience: "Min. 1 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$500/ month",
  //     jobTimeDate: "2 min ago",
  //     addclassNameBookmark: false,
  //     location: "World-Wide",
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 2,
  //     companyImg: jobImage2,
  //     jobDescription: "Marketing Director",
  //     experience: "Min. 3 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Director",
  //     salary: "$850/ month",
  //     jobTimeDate: "15 days ago",
  //     addclassNameBookmark: true,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 3,
  //     companyImg: jobImage3,
  //     jobDescription: "Magento Developer",
  //     experience: "0-1 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$780/ month",
  //     jobTimeDate: "2 hrs ago",
  //     addclassNameBookmark: false,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 4,
  //     companyImg: jobImage4,
  //     jobDescription: "Project Leader",
  //     experience: "2 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$900/ month",
  //     jobTimeDate: "30 min ago",
  //     addclassNameBookmark: false,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 5,
  //     companyImg: jobImage5,
  //     jobDescription: "Graphic Designer",
  //     experience: "0.6 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Designer",
  //     salary: "$350/ month",
  //     jobTimeDate: "2 days ago",
  //     addclassNameBookmark: false,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 6,
  //     companyImg: jobImage6,
  //     jobDescription: "Store Manager",
  //     experience: "Min. 1.5 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Manager",
  //     salary: "$950/ month",
  //     jobTimeDate: "2 days ago",
  //     addclassNameBookmark: true,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 7,
  //     companyImg: jobImage7,
  //     jobDescription: "Product Designer",
  //     experience: "Min. 1 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$750/ month",
  //     jobTimeDate: "1 hrs ago",
  //     addclassNameBookmark: true,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 8,
  //     companyImg: jobImage8,
  //     jobDescription: "Business Associate",
  //     experience: "Min. 1 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$780/ month",
  //     jobTimeDate: "2 days ago",
  //     addclassNameBookmark: false,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   },
  //   {
  //     id: 9,
  //     companyImg: jobImage9,
  //     jobDescription: "Product Sales Specialist",
  //     experience: "Min. 1 Year",
  //     companyName: "TalentBase Technology Pvt.Ltd",
  //     jobType: "Developer",
  //     salary: "$780/ Month",
  //     jobTimeDate: "2 days ago",
  //     addclassNameBookmark: false,
  //     jobDetails:
  //       "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
  //   }
  // ];
  return (
    <React.Fragment>
      {jobVacancyList.map((jobVacancy2Details, key) => (
        <Col lg={4} md={6} className="mt-4" key={key}>
          <div
            className={
              jobVacancy2Details.addclassNameBookmark === true
                ? "card job-grid-box bookmark-post"
                : "card job-grid-box"
            }
          >
            <div className="card-body p-4">
              <div className="favorite-icon">
                <Link to="#">
                  <i className="uil uil-heart-alt"></i>
                </Link>
              </div>
              <div>
                <Link to="/job-details">
                  <img
                    src={jobVacancy2Details.imageUrl || companyImage}
                    alt=""
                    className="img-fluid rounded-3"
                  />
                </Link>
              </div>
              <div className="mt-4">
                <Link to="/job-details" className="primary-link">
                  <h5 className="fs-17 mb-1">
                    {jobVacancy2Details.jobDescription}
                  </h5>
                </Link>
                <Link to="/job-details">
                  <div className="text-muted mb-2">
                    <div>{jobVacancy2Details.companyName}</div>
                    <span className="text-muted fs-12 mb-0">
                      <i className="mdi mdi-map-marker"></i>{" "}
                      {jobVacancy2Details.location}
                    </span>
                  </div>

                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <span className="badge bg-success-subtle text-success fs-13 mt-1">
                        {jobVacancy2Details.salary}
                      </span>
                    </li>
                    <li className="list-inline-item">
                      <span className="badge bg-primary-subtle text-primary fs-13 mt-1">
                        {jobVacancy2Details.experience}
                      </span>
                    </li>
                    <li className="list-inline-item">
                      {getAccessToken() && <span className="badge bg-danger-subtle text-danger fs-13 mt-1">
                        {t("jobVacancy.matching")}: 80%
                      </span>}
                    </li>
                  </ul>
                </Link>
              </div>
              <div className="job-grid-content mt-3">
                <Link to="/job-details">
                  <p className="text-muted text-truncate-3">{jobVacancy2Details.jobDetails}</p>
                </Link>
                <div className="d-flex align-items-center justify-content-between mt-4 border-top pt-3">
                  <p className="text-muted float-start mb-0">
                    <i className="mdi mdi-history"></i>{" "}
                    {jobVacancy2Details.jobTimeDate}
                  </p>
                  <div className="text-end">
                    <a
                      href="#applyNow"
                      onClick={openModal}
                      className="btn btn-sm btn-primary"
                    >
                      {t("jobVacancy.applyNow")} <i className="uil uil-angle-right-b"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
      <div
        className="modal fade"
        id="applyNow"
        tabIndex="-1"
        aria-labelledby="applyNow"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered>
            <ModalBody className="modal-body p-5">
              <div className="text-center mb-4">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {t("jobVacancy.applyJob")}
                </h5>
              </div>
              <div className="position-absolute end-0 top-0 p-3">
                <button
                  type="button"
                  onClick={openModal}
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="mb-3">
                <Label for="nameControlInput" className="form-label">
                  {t("jobVacancy.name")}
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="nameControlInput"
                  placeholder={t("course.enterYourName")}
                />
              </div>
              <div className="mb-3">
                <Label for="emailControlInput2" className="form-label">
                  {t("course.emailAddress")}
                </Label>
                <Input
                  type="email"
                  className="form-control"
                  id="emailControlInput2"
                  placeholder={t("course.enterYourEmail")}
                />
              </div>
              <div className="mb-3">
                <Label for="messageControlTextarea" className="form-label">
                  {t("course.message")}
                </Label>
                <textarea
                  className="form-control"
                  id="messageControlTextarea"
                  rows="4"
                  placeholder={t("course.enterMessage")}
                ></textarea>
              </div>
              <div className="mb-4">
                <Label className="form-label" for="inputGroupFile01">
                  {t("course.resumeUpload")}
                </Label>
                <Input
                  type="file"
                  className="form-control"
                  id="inputGroupFile01"
                />
              </div>
              <button type="submit" className="btn btn-primary w-100">
                {t("course.sendApplication")}
              </button>
            </ModalBody>
          </Modal>
        </div>
      </div>

      <Row>
        <Col lg={12} className="mt-4 pt-2">
          <nav aria-label="Page navigation example">
            <div className="pagination job-pagination mb-0 justify-content-center">
              <li onClick={(e) => decreaseSegement(1)} className="page-item disabled">
                <Link className="page-link" >
                  <i className="mdi mdi-chevron-double-left fs-15"></i>
                </Link>
              </li>
              {pagination()}
              <li onClick={(e) => incrementSegement(1)} className="page-item">
                <Link className="page-link" to="#">
                  <i className="mdi mdi-chevron-double-right fs-15"></i>
                </Link>
              </li>
            </div>
          </nav>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default JobVacancyGrid;
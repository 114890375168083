// import React from "react";
// import SignIn from "../pages/Authentication/sign-in/SignIn";
// import SignUp from "../pages/Authentication/sign-up/SignUp";
// import ResetPassword from "../pages/Authentication/reset-password/ResetPassword";
// import AboutUs from "../pages/Company/AboutUs/AboutUs";
// import ContactUs from "../pages/Company/ContactUs/ContactUs";
// import Terms from "../pages/Company/Terms/Terms";

// import Faqs from "../pages/Company/FAQ/Faqs";
// import Error404 from "../pages/Common/Error/Error404";
// import Jobs from "../pages/Job/JobCards/Jobs";
// import Courses from "../pages/Course/Courses";
// import MyProfile from "../pages/Profile/Profile";
// import MySkills from "../pages/MySkills/MySkills";
// import JobDetails from "../pages/Job/JobDetails/JobDetails";
// import JobsCategories from "../pages/Job/JobCategories/JobsCategories";
// import AuthSuccessGoogle from "../pages/Authentication/auth-success-google/AuthSuccessGoogle";

// const Home = React.lazy(() => import('../pages/Home/Home'));

// const userRoutes = [
//   { path: "/", component: <Home /> },
//   { path: "/home", component: <Home /> },
//   { path: "/aboutus", component: <AboutUs /> },
//   { path: "/job-categories", component: <JobsCategories /> },
//   { path: "/about", component: <AboutUs /> },
//   { path: "/contact", component: <ContactUs /> },
//   { path: "/faqs", component: <Faqs /> },
//   { path: "/jobs", component: <Jobs /> },
//   { path: "/courses", component: <Courses /> },
//   { path: "/profile", component: <MyProfile /> },
//   { path: "/my-skills", component: <MySkills /> },
//   { path: "/job-details", component: <JobDetails /> },
//   { path: "/auth-success", component: <AuthSuccessGoogle /> }
// ];

// const authRoutes = [
//   { path: "/error404", component: <Error404 /> },
//   { path: "/signin", component: <SignIn /> },
//   { path: "/signUp", component: <SignUp /> },
//   { path: "/resetpassword", component: <ResetPassword /> },
// ];

// export { userRoutes, authRoutes };

import React from "react";
import SignIn from "../pages/Authentication/sign-in/SignIn";
import SignUp from "../pages/Authentication/sign-up/SignUp";
import ResetPassword from "../pages/Authentication/reset-password/ResetPassword";
import AboutUs from "../pages/Company/AboutUs/AboutUs";
import ContactUs from "../pages/Company/ContactUs/ContactUs";
import Terms from "../pages/Company/Terms/Terms";  // Import Terms page
import Pricing from "../pages/Company/Pricing/Pricing";  // Import Terms page
import SignUpEmployer from "../pages/Company/SignUpEmployer/SignUpEmployer";  // Import Terms page



import Faqs from "../pages/Company/FAQ/Faqs";
import Error404 from "../pages/Common/Error/Error404";
import Jobs from "../pages/Job/JobCards/Jobs";
import Courses from "../pages/Course/Courses";
import MyProfile from "../pages/Profile/Profile";
import MySkills from "../pages/MySkills/MySkills";
import JobDetails from "../pages/Job/JobDetails/JobDetails";
import JobsCategories from "../pages/Job/JobCategories/JobsCategories";
import AuthSuccessGoogle from "../pages/Authentication/auth-success-google/AuthSuccessGoogle";

const Home = React.lazy(() => import('../pages/Home/Home'));

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/home", component: <Home /> },
  { path: "/aboutus", component: <AboutUs /> },
  { path: "/job-categories", component: <JobsCategories /> },
  { path: "/about", component: <AboutUs /> },
  { path: "/contact", component: <ContactUs /> },
  { path: "/faqs", component: <Faqs /> },
  { path: "/jobs", component: <Jobs /> },
  { path: "/courses", component: <Courses /> },
  { path: "/profile", component: <MyProfile /> },
  { path: "/my-skills", component: <MySkills /> },
  { path: "/job-details", component: <JobDetails /> },
  { path: "/terms", component: <Terms /> },  // Add this line for the Terms route
  { path: "/pricing", component: <Pricing /> },  // Add this line for the Terms route
  { path: "/sign-up-employer", component: <SignUpEmployer /> },  // Add this line for the Terms route


  { path: "/auth-success", component: <AuthSuccessGoogle /> }
];

const authRoutes = [
  { path: "/error404", component: <Error404 /> },
  { path: "/signin", component: <SignIn /> },
  { path: "/signUp", component: <SignUp /> },
  { path: "/resetpassword", component: <ResetPassword /> },
];

export { userRoutes, authRoutes };

import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, ModalBody, Input, Label } from "reactstrap";
import { Link } from "react-router-dom";
import { getAccessToken } from "../../services/User.service";

import jobImage1 from "../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../assets/images/featured-job/img-07.png";
import jobImage8 from "../../assets/images/featured-job/img-08.png";
import jobImage9 from "../../assets/images/featured-job/img-09.png";

import courseImage1 from "../../assets/images/courses/html.jpg";
import courseImage2 from "../../assets/images/courses/ml.jpg";
import courseImage3 from "../../assets/images/courses/ai-01.jpg";
import courseImage4 from "../../assets/images/courses/cloud.png";
import courseImage5 from "../../assets/images/courses/maths.jpg";
import courseImage6 from "../../assets/images/courses/ai-02.jpg";
import courseImage7 from "../../assets/images/courses/marketing.jpg";
import courseImage8 from "../../assets/images/courses/financial.jpg";
import courseImage9 from "../../assets/images/courses/coop.jpg";

const CourseGrid = () => {
  const { t } = useTranslation();
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [progress, setProgress] = useState(50);

  const jobVacancy2 = [
    {
      id: 1,
      companyImg: jobImage1,
      courseImg: courseImage1,
      jobDescription: "Magento Developer",
      experience: "Min. 1 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$500/ month",
      jobTimeDate: "6-8 Months",
      addclassNameBookmark: false,
      location: "World-Wide",
      progress: 10,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 2,
      companyImg: jobImage2,
      courseImg: courseImage2,
      jobDescription: "Marketing Director",
      experience: "Min. 3 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Director",
      salary: "$850/ month",
      jobTimeDate: "2 Months",
      addclassNameBookmark: true,
      progress: 0,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 3,
      companyImg: jobImage3,
      courseImg: courseImage3,
      jobDescription: "Magento Developer",
      experience: "0-1 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$780/ month",
      jobTimeDate: "1 year",
      addclassNameBookmark: false,
      progress: 40,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 4,
      companyImg: jobImage4,
      courseImg: courseImage4,
      jobDescription: "Project Leader",
      experience: "2 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$900/ month",
      jobTimeDate: "6-8 Months",
      addclassNameBookmark: false,
      progress: 0,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 5,
      companyImg: jobImage5,
      courseImg: courseImage5,
      jobDescription: "Graphic Designer",
      experience: "0.6 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Designer",
      salary: "$350/ month",
      jobTimeDate: "3-6 Months",
      addclassNameBookmark: false,
      progress: 50,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 6,
      companyImg: jobImage6,
      courseImg: courseImage6,
      jobDescription: "Store Manager",
      experience: "Min. 1.5 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Manager",
      salary: "$950/ month",
      jobTimeDate: "2 Months",
      addclassNameBookmark: true,
      progress: 60,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 7,
      companyImg: jobImage7,
      courseImg: courseImage7,
      jobDescription: "Product Designer",
      experience: "Min. 1 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$750/ month",
      jobTimeDate: "1 Year",
      addclassNameBookmark: true,
      progress: 70,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 8,
      companyImg: jobImage8,
      courseImg: courseImage8,
      jobDescription: "Business Associate",
      experience: "Min. 1 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$780/ month",
      jobTimeDate: "3-6 Months",
      addclassNameBookmark: false,
      progress: 20,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    },
    {
      id: 9,
      companyImg: jobImage9,
      courseImg: courseImage9,
      jobDescription: "Product Sales Specialist",
      experience: "Min. 1 Year",
      companyName: "TalentBase Technology Pvt.Ltd",
      jobType: "Developer",
      salary: "$780/ Month",
      jobTimeDate: "3-6 Months",
      addclassNameBookmark: false,
      progress: 0,
      jobDetails:
        "As a Product Designer, you will work within a Product Delivery Team fused with UX, engineering, product and data talent."
    }
  ];
  return (
    <React.Fragment>
      <div>
        <h5 className="mt-3">Continue Learning</h5>
      </div>
      {jobVacancy2.map((jobVacancy2Details, key) => (
        <Col lg={4} md={6} className="mt-4" key={key}>
          <div
            className={
              jobVacancy2Details.addclassNameBookmark === true
                ? "card job-grid-box courses-box bookmark-post"
                : "card job-grid-box courses-box"
            }
          >
            <div className="card-body p-4">
              <div className="courses-header-img">
                <Link to="/companydetails" className="h-100 w-100">
                  <img
                    src={jobVacancy2Details.courseImg}
                    alt=""
                    className="img-fluid rounded-3 h-100 w-100"
                  />
                </Link>
              </div>
              <div className="mt-3">
                <div className="favorite-icon">
                  <Link to="#">
                    <i className="uil uil-heart-alt"></i>
                  </Link>
                </div>
                <div>
                  <Link to="/companydetails">
                    <img
                      src={jobVacancy2Details.companyImg}
                      alt=""
                      className="img-fluid rounded-3 company-img-courses"
                    />
                  </Link>
                </div>
              </div>

              <div className="mt-4">
                <Link to="/jobdetails" className="primary-link">
                  <h5 className="fs-17 mb-1">
                    {jobVacancy2Details.jobDescription}
                  </h5>
                </Link>
                <p className="text-muted">{jobVacancy2Details.companyName}</p>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <span className="badge bg-success-subtle text-success fs-13 mt-1">
                      {jobVacancy2Details.salary}
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span className="badge bg-primary-subtle text-primary fs-13 mt-1">
                      {jobVacancy2Details.experience}
                    </span>
                  </li>
                  <li className="list-inline-item">
                    <span className="badge bg-blue-subtle text-primary fs-13 mt-1 ps-0">
                      {t("course.fillSkillGap")}: 85%
                    </span>
                  </li>
                </ul>
              </div>
              <div className="job-grid-content mt-3">
                <p className="text-muted truncate-3-lines">
                  {jobVacancy2Details.jobDetails}
                </p>
                <div className="pt-3 border-top d-flex align-items-center">
                  <ul className="list-inline d-flex justify-content-between align-items-center mb-0">
                    <li className="list-inline-item text-warning fs-17">
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star-half-full"></i>
                    </li>
                  </ul>
                  <div className="ms-2 fs-13">(20K {t("course.reviews")})</div>
                </div>

                {getAccessToken() && jobVacancy2Details.progress > 0 && <div className="my-2">
                  <span className="text-muted fs-14 mb-0">
                    {t("course.learningProgress")}:
                  </span>
                  <div className="progress" style={{ height: "30px" }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${jobVacancy2Details.progress}%` }}
                      aria-valuenow={jobVacancy2Details.progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {jobVacancy2Details.progress}%
                    </div>
                  </div>
                </div>}

                <div className="d-flex align-items-center justify-content-between mt-1 pt-3">
                  <p className="text-muted float-start mb-0">
                    <i className="uil uil-clock me-2"></i>
                    {jobVacancy2Details.jobTimeDate}
                  </p>
                  <div className="text-end">
                    {jobVacancy2Details.progress > 0 && <a
                      href="#"
                      className="btn btn-sm btn-primary"
                    >
                      {t("course.continue")} <i className="uil uil-angle-right-b"></i>
                    </a>}
                    {
                      jobVacancy2Details.progress == 0 && <a
                        href="#applyNow"
                        onClick={openModal}
                        className="btn btn-sm btn-primary"
                      >
                        Enroll Now <i className="uil uil-angle-right-b"></i>
                      </a>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default CourseGrid;
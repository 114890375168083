const baseUrl = process.env.REACT_APP_API_HOST;

export const UserApiEndPoints = {
    "SignUp": baseUrl + "/api/signup/",
    "SignIn": baseUrl + "/api/signin/",
    "PasswordReset": baseUrl + "/api/password-reset/",
    "Profile": baseUrl + "/api/profile/",
    "RefreshToken": baseUrl + "/api/token/refresh/"
};

export const JobApiEndPoints = {
    "autoCompleteOccupation": baseUrl + "/api/auto-complete-occupation/",
    "jobList": baseUrl + "/api/jobs/"
};

export const IndustryEndPoints = {
    "industry": baseUrl + "/api/industries/"
};
import React from "react";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import ReactDOM from "react-dom/client";
// import AlertTemplate from 'react-alert-template-basic';
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import './index.scss';

const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '10px',
  // you can also just use 'scale'
  transition: transitions.SCALE
}

const AlertTemplate = ({ style, message, close }) => {
  let data = JSON.parse(message);

  if (data.type == 'success') {
    return (<div style={style} className="alert alert-success alert-dismissible fade show" role={alert}>
      <button type="button" class="btn-close" aria-label="Close" onClick={close}></button>
      <div className="fw-semibold">{data.title}</div>
      {data.message}
    </div>);
  }
  else if (data.type == 'info') {
    return (<div style={style} className="alert alert-info alert-dismissible fade show" role={alert}>
      <button type="button" class="btn-close" aria-label="Close" onClick={close}></button>
      {data.message}
    </div>)
  }
};


const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.Fragment>
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AlertProvider>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
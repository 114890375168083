import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Input, Modal, ModalBody, Label } from "reactstrap";
import { Form } from "react-bootstrap";
import { updateProfile } from "../../../../services/User.service";

const ProfileSkillInterest = ({ modal = false, openModal, callBackSubmit }) => {
  const { t } = useTranslation();
  const [skills, setSkills] = useState([]);
  const [currentSkill, setCurrentSkill] = useState([]);

  const onSubmitEvent = async (e) => {
    e.preventDefault();
    await updateSkill();
    callBackSubmit()
  }

  const updateSkill = async () => {
    try {
      let data = [];

      let requestData = {
        skills: data
      };

      return await updateProfile(requestData);

    }
    catch (err) {
      console.log(err);
    }
  };

  const closeModal = (e) => {
    e.preventDefault();
    callBackSubmit()
  }

  const addSkill = () => {
    setSkills([...skills, currentSkill]);
  }

  return (
    <div className="modal-dialog modal-dialog-centered">
      <Modal isOpen={modal} toggle={openModal} centered>
        <ModalBody className="modal-body p-5">
          <div className="text-center mb-4">
            <h5 className="modal-title" id="staticBackdropLabel">
              {t("profileSkillInterest.title")}
            </h5>
            <div>{t("profileSkillInterest.selectSkills")}</div>
          </div>
          <div className="position-absolute end-0 top-0 p-3">
            <button
              type="button"
              onClick={openModal}
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>

          <div className="mb-3">
            <Label for="nameControlInput" className="form-label">
              {t("profileSkillInterest.skillsInterest")}
            </Label>
            <div className="d-flex">
              <div className="flex-grow-1 me-2">
                <Input
                  type="text"
                  className="form-control"
                  id="nameControlInput"
                  placeholder={`${t("profileSkillInterest.skill")}`}
                  onChange={(e) => setCurrentSkill(e.target.value)}
                />
              </div>
              <div style={{ width: "25%" }}>
                <button
                  type="button"
                  onClick={addSkill}
                  className="btn btn-primary w-100"
                >
                  {t("profileSkillInterest.add")}
                </button>
              </div>
            </div>
          </div>

          <div class="d-flex flex-wrap align-items-start gap-1 mb-3">
            {skills &&
              skills.length > 0 &&
              skills.map((data) => {
                return <span class="badge rounded-pill bg-primary">{data}</span>
              })}
          </div>

          <div className="d-flex gap-2">
            <button type="button" onClick={e => closeModal(e)} className="btn btn-outline-primary w-100">
              {t("profileSkillInterest.addLater")}
            </button>

            <button
              type="button"
              onClick={onSubmitEvent}
              className="btn btn-primary w-100"
            >
              {t("profileSkillInterest.next")}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProfileSkillInterest;
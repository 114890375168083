import React, { useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Input, Label, FormGroup } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import useIsNotificationSidePanelOpenStore from "../../../stores/SidePanel/NotifcationSidePanel.store";

function NotificatiionRightSidePanel() {
  const { t } = useTranslation();
  const { isNotificationSidePanelOpen, setIsNotificationSidePanelOpen } =
    useIsNotificationSidePanelOpenStore((state) => state);

  const togglePanel = () => {
    setIsNotificationSidePanelOpen(!isNotificationSidePanelOpen);
  };

  return (
    <div>
      {/* Button to trigger the side panel */}
      {/* <button
        className="btn btn-primary"
        onClick={togglePanel}
        aria-controls="offcanvasRight"
      >
        Open Right Panel
      </button> */}

      {/* Right Side Panel (Offcanvas) */}
      <div
        className={`offcanvas offcanvas-end ${
          isNotificationSidePanelOpen ? "show" : ""
        }`}
        id="offcanvasRight"
        tabIndex="-1"
        aria-labelledby="offcanvasRightLabel"
        style={{
          visibility: isNotificationSidePanelOpen ? "visible" : "hidden",
        }}
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">{t("notificationSidePanel.notifications")}</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={togglePanel}
          ></button>
        </div>
        <div className="offcanvas-body offcanvas-body--notifications">
          <div className="notification-header border-bottom bg-light">
              <div className="d-flex flex-wrap align-items-start gap-1">
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-bell fs-12"></i>
                  <span className="fs-12">{t("notificationSidePanel.all")}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-star fs-12"></i>
                  <span className="fs-12">{t("notificationSidePanel.special")}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-comment fs-12"></i>
                  <span className="fs-12">{t("notificationSidePanel.general")}</span>
                </button>
                <button
                  type="button"
                  class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1 "
                >
                  <i class="uil uil-envelope-alt fs-12"></i>
                  <span className="fs-12">{t("notificationSidePanel.unread")}</span>
                </button>
                <button
                type="button"
                class="btn btn-outline-primary py-1 px-2 rounded-pill text-capitalize d-flex gap-1"
              >
                <span className="fs-12">{t("notificationSidePanel.clearAll")}</span>
              </button>
              </div>
          </div>
          <div className="notification-wrapper dropdown-scroll">
            <Link to="#" className="text-dark notification-item d-block active">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-xs bg-primary text-white rounded-circle text-center">
                    <i className="uil uil-user-check"></i>
                  </div>
                </div>
                <div className="flex-grow-1">
                  {/* For unread msgs  */}
                  <h6 className="d-flex mt-0 mb-1 fs-14 gap-1">
                    {/* for special msgs  */}
                    <i className="uil uil-star text-primary"></i>
                    <span>22 verified registrations</span>
                  </h6>
                  {/* For read msgs  */}
                  <div>22 verified registrations</div>
                  <p className="mb-0 fs-12 text-muted">
                    <i className="mdi mdi-clock-outline"></i>{" "}
                    <span>3 min ago</span>
                  </p>
                </div>
                <div className="flex-shrink-0 ms-3">
                  <div className="text-muted rounded-circle text-center">
                    <i className="uil uil-times-circle"></i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificatiionRightSidePanel;

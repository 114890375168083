import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Col, Input, Label, Row, Modal, ModalBody, FormGroup } from "reactstrap";
import { getAccessToken } from "../../services/User.service";
import Pagination from "./Pagination";

//Images Import
import jobImage1 from "../../assets/images/featured-job/img-01.png";
import jobImage2 from "../../assets/images/featured-job/img-02.png";
import jobImage3 from "../../assets/images/featured-job/img-03.png";
import jobImage4 from "../../assets/images/featured-job/img-04.png";
import jobImage5 from "../../assets/images/featured-job/img-05.png";
import jobImage6 from "../../assets/images/featured-job/img-06.png";
import jobImage7 from "../../assets/images/featured-job/img-07.png";

const courses = [
  {
    id: 4,
    companyImg: jobImage4,
    jobDescription: "Product Director",
    companyName: "Creative Agency",
    location: " Escondido,California",
    jobPostTime: "50 min ago",
    freeLance: true,
    timing: "Freelance",
    addclassNameBookmark: false,
    badges: [],
    experience: "2 - 3 years",
    salary: "$500/Month",
    matchingPercentage: 75,
    progress: 0,
    jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
  },
  {
    id: 5,
    companyImg: jobImage5,
    jobDescription: "Product Director",
    companyName: "Creative Agency",
    location: " Escondido,California",
    jobPostTime: "1 month ago",
    partTime: true,
    timing: "Part Time",
    addclassNameBookmark: true,
    badges: [],
    experience: "2 - 3 years",
    salary: "$1500/Month",
    matchingPercentage: 85,
    progress: 16,
    jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
  },
  {
    id: 6,
    companyImg: jobImage6,
    jobDescription: "Product Director",
    companyName: "Creative Agency",
    location: "Escondido, California",
    jobPostTime: "2 month ago",
    freeLance: true,
    timing: "Freelance",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Urgent"
      }
    ],
    experience: "2-3 years",
    salary: "$700/Month",
    matchingPercentage: 80,
    progress: 60,
    jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
  },
  {
    id: 7,
    companyImg: jobImage7,
    jobDescription: "Product Director",
    companyName: "Creative Agency",
    location: "Escondido, California",
    jobPostTime: "2 month ago",
    partTime: true,
    timing: "Part Time",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Urgent"
      }
    ],
    experience: "2-3 years",
    salary: "$800/Month",
    progress: 0,
    matchingPercentage: 90,
    jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
  },
  {
    id: 8,
    companyImg: jobImage3,
    jobDescription: "Product Director",
    companyName: "Creative Agency",
    location: "Escondido, California",
    jobPostTime: "3 month ago",
    internship: true,
    timing: "Internship",
    addclassNameBookmark: false,
    badges: [
      {
        id: 1,
        badgeclassName: "bg-warning-subtle text-warning",
        badgeName: "Private"
      }
    ],
    experience: "2-3 years",
    salary: "$1200/Month",
    matchingPercentage: 55,
    progress: 70,
    jobLongDescription: "We are looking for an experienced Product Owner who is passionate about building products that customers love and take full accountability for the discovery, definition, delivery and performance of products and services in the Payments portfolio."
  }
];

const CourseList = () => {
  const { t } = useTranslation();
  //Apply Now Model
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);
  const [progress, setProgress] = useState(50);
  const [sectionAndCourses, setSectionAndCourses] = useState([]);

  // for ratings 
  const [rating, setRating] = useState(4.5); // Default rating

  // Function to handle star click
  const handleRating = (value) => {
    setRating(value); // Update the rating state
    console.log(`Rating clicked: ${value} stars`);
  };

  useEffect(() => {
    let tempCourses = [];
    tempCourses.push({
      name: "Featured Courses",
      courses: courses
    });

    if (getAccessToken()) {
      tempCourses.push({
        name: "Continue Learning",
        courses: courses
      },
        {
          name: "Recommended Courses",
          courses: courses
        })
    }

    setSectionAndCourses(tempCourses);
  }, []);


  return (
    <React.Fragment>
      {sectionAndCourses.map((section, key) => {
        return (<>
          <div>
            <h5 className="mt-3">{section.name}</h5>
          </div>
          <div>
            {section.courses.map((courseListDetails, key) => (
              <div
                key={key}
                className={
                  courseListDetails.addclassNameBookmark === true
                    ? "job-box bookmark-post card mt-4"
                    : "job-box card mt-4"
                }
              >
                <div className="bookmark-label text-center">
                  <Link to="#" className="align-middle text-white">
                    <span className="fs-8">{t("course.free")}</span>
                  </Link>
                </div>
                <div className="px-5 py-4">
                  <Row>
                    <Col lg={1}>
                      <Link to="/companydetails">
                        <img
                          src={courseListDetails.companyImg}
                          alt=""
                          className="img-fluid rounded-3"
                        />
                      </Link>
                    </Col>
                    <Col lg={11}>
                      <div className="mt-3 mt-lg-0">
                        <h5 className="fs-17 mb-1">
                          <Link to="/jobdetails" className="text-dark">
                            {courseListDetails.jobDescription}
                          </Link>{" "}
                        </h5>
                        <div className="d-md-flex justify-content-between">
                          <div>
                            <ul className="list-inline mb-0">
                              <li className="list-inline-item">
                                <p className="text-muted fs-14 mb-0">
                                  {courseListDetails.companyName}
                                </p>
                              </li>
                            </ul>
                          </div>

                          <div className="d-flex flex-wrap align-items-start gap-1">
                            <span className="badge bg-success-subtle text-success">
                              <i className="uil uil-graduation-cap"></i> {t("course.type")}:
                              {t("course.degree")}
                            </span>
                            <span className="badge bg-primary-subtle text-primary">
                              <i className="uil uil-clock"></i> Duration: 2 Years
                            </span>
                            {getAccessToken() && <span className="badge bg-danger-subtle text-danger">
                              <i className="uil uil-check-circle"></i> {t("course.learningProgress")}:
                              : {courseListDetails.matchingPercentage}
                              %
                            </span>}
                          </div>
                        </div>
                        <div className="my-2">
                          <p className="text-truncate-3 text-muted fs-12 mb-0">
                            {courseListDetails.jobLongDescription}
                          </p>
                        </div>
                        {getAccessToken() && section.name != "Featured Courses" && section.name != "Recommended Courses" && courseListDetails.progress > 0 && <div className="w-sm-100 w-50 mb-2">
                          <span className="text-muted fs-14 mb-0">
                            {t("course.learningProgress")}:
                          </span>
                          <div className="progress" style={{ height: "30px" }}>
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${courseListDetails.progress}%` }}
                              aria-valuenow={courseListDetails.progress}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {courseListDetails.progress}%
                            </div>
                          </div>
                        </div>}
                        {getAccessToken() && <div className="text-end">
                          {section.name == "Continue Learning" && <a
                            href=""
                            onClick={openModal}
                            className="btn btn-sm btn-primary"
                          >
                            {t("course.continue")} <i className="uil uil-angle-right-b"></i>
                          </a>}
                          {section.name != "Continue Learning" &&
                             <a
                              href="#applyNow"
                              onClick={openModal}
                              className="btn btn-sm btn-primary"
                            >
                              Enroll Now <i className="uil uil-angle-right-b"></i>
                            </a>
                          }
                        </div>}
                      </div>
                    </Col>
                  </Row>
                  <div className="favorite-icon">
                    <Link to="#">
                      <i className="uil uil-heart-alt fs-18"></i>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <Pagination />
        </>)
      })}
      <div>

        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    {t("course.applyForThisJob")}
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    {t("course.name")}
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder={t("course.enterYourName")}
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    {t("course.emailAddress")}
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder={t("course.enterYourEmail")}
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    {t("course.message")}
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder={t("course.enterMessage")}
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    {t("course.resumeUpload")}
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  {t("course.sendApplication")}
                </button>
              </ModalBody>
            </Modal>
          </div>
        </div>
      </div>

      {/* Feedback Section  */}
      <div className="my-5 d-flex flex-column align-items-center justify-content-center">
        <h6>{t("course.leaveFeedback")}</h6>
        <ul className="list-inline d-flex justify-content-between align-items-center mb-0">
          <li className="list-inline-item text-primary fs-17">
            <button
              className="btn p-0"
              onClick={() => handleRating(1)}
              aria-label="Rate 1 star"
            >
              <i
                className={`mdi ${rating >= 1 ? "mdi-star text-primary" : "mdi-star-outline"
                  }`}
              ></i>
            </button>
            <button
              className="btn p-0"
              onClick={() => handleRating(2)}
              aria-label="Rate 2 stars"
            >
              <i
                className={`mdi ${rating >= 2 ? "mdi-star text-primary" : "mdi-star-outline"
                  }`}
              ></i>
            </button>
            <button
              className="btn p-0"
              onClick={() => handleRating(3)}
              aria-label="Rate 3 stars"
            >
              <i
                className={`mdi ${rating >= 3 ? "mdi-star text-primary" : "mdi-star-outline"
                  }`}
              ></i>
            </button>
            <button
              className="btn p-0"
              onClick={() => handleRating(4)}
              aria-label="Rate 4 stars"
            >
              <i
                className={`mdi ${rating >= 4 ? "mdi-star text-primary" : "mdi-star-outline"
                  }`}
              ></i>
            </button>
            <button
              className="btn p-0"
              onClick={() => handleRating(4.5)}
              aria-label="Rate 4.5 stars"
            >
              <i
                className={`mdi ${rating === 4.5
                  ? "mdi-star-half-full text-primary"
                  : rating >= 5
                    ? "mdi-star"
                    : "mdi-star-outline"
                  }`}
              ></i>
            </button>
          </li>
        </ul>
        <div className="w-50">
          <FormGroup>
            <Input
              type="textarea"
              className="form-control"
              id="nameControlInput"
              placeholder="Type here..."
            />
          </FormGroup>
          <div className="text-end">
            <a href="#" className="btn btn-sm btn-primary">
              {t("course.send")} <i className="uil uil-angle-right-b"></i>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseList;
import React, { useState, useEffect } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import useJobGridStore from "../../../stores/JobCards/JobGrid.store";
import CountryOptions from "../../Common/SubSection/CountryOptions";
import JobType from "../../Common/SubSection/JobType";
import {
    Input,
    Col,
    Row,
    Container,
    Form,
    Collapse,
    NavbarToggler,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    Button,
    Label,
    Tooltip
} from "reactstrap";
import { fetchIndustries } from "../../../services/Job.service";

const JobGridHeader = () => {
    const { t } = useTranslation();
    const [industries, setIndustries] = useState([]);

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    //Advanced Filter Dropdown
    const [filterDD, setFilterDD] = useState(false);
    const dropDownFilter = () => setFilterDD((prevState) => !prevState);


    // For Filter Dropdown 
    const [toggleFirst, setToggleFirst] = useState(true);
    const [toggleSecond, setToggleSecond] = useState(true);
    const [toggleThird, setToggleThird] = useState(true);
    const [toggleFourth, setToggleFourth] = useState(true);
    const [toggleFifth, setToggleFifth] = useState(true);
    const [value, setValue] = React.useState(50);
    const [occupationList, setOccupationList] = React.useState([]);

    const { isGrid, setIsGrid } = useJobGridStore(state => state);

    useEffect(() => {
        getIndustries();
    }, []);

    const getIndustries = async () => {
        try {
            let response = await fetchIndustries();
            if (response) {
                let tempData = response.map((data) => {
                    data.checked = false;
                    return data;
                });
                setIndustries(tempData);
                return true;

            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const [isDateChecked, setIsDateChecked] = useState(true);
    const handleDateOnChange = () => {
        setIsDateChecked(!isDateChecked);
    };

    const handleOnChangeIndustry = (e, index) => {
    };

    return (
        <React.Fragment>
            <form action="#">
                <Row className="g-2">
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-briefcase-alt"></i>
                            <Input
                                type="search"
                                className="form-control filter-input-box ps-5"
                                id="exampleFormControlInput1"
                                placeholder={t("job.jobSearchPlaceHolder")}
                                style={{ marginTop: "-10px" }}
                            />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-location-point"></i>
                            <CountryOptions />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <div className="filler-job-form">
                            <i className="uil uil-clipboard-notes"></i>
                            <JobType />
                        </div>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link to="#" className="btn btn-primary w-100">
                            <i className="uil uil-search"></i> {t("course.search")}
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(false)} className={`btn btn-primary w-100 ${isGrid ? 'opacity-75' : ''}`}>
                            <i className="uil uil-list-ul"></i> {t("course.listView")}
                        </Link>
                    </Col>
                    <Col lg={2} md={6}>
                        <Link onClick={(e) => setIsGrid(true)} className={`btn btn-primary w-100 ${!isGrid ? 'opacity-75' : ''}`}>
                            <i className="uil uil-apps"></i> {t("course.gridView")}
                        </Link>
                    </Col>
                </Row>
                <Row className="g-2 mt-1">
                    <Col lg={3} md={6}>
                        <div className="mt-3 mt-md-0 h-100">
                            <Dropdown
                                isOpen={filterDD}
                                toggle={dropDownFilter}
                                className="list-inline-item me-1"
                            >
                                <DropdownToggle
                                    className="position-relative"
                                    id="filterDD"
                                    type="button"
                                    tag="a"
                                >
                                    <button
                                        className="btn btn-primary w-100 h-100"
                                        type="button"
                                    >
                                        <i className="uil uil-filter me-1"></i>{t("jobSearch.advancedFilters")}
                                    </button>
                                </DropdownToggle>
                                <DropdownMenu
                                    className="dropdown-menu-sm dropdown-menu-end adv-filter-dd p-0"
                                    aria-labelledby="filterDD"
                                    end
                                >
                                    <div className="notification-header border-bottom bg-light">
                                        <h6 className="mb-1">{t("jobSearch.filters")}</h6>
                                    </div>
                                    <div className="notification-wrapper dropdown-scroll">
                                        <div className="side-bar mt-5 mt-lg-0">
                                            <div
                                                className="accordion"
                                                id="accordionExample"
                                            >
                                                <div className="accordion-item mt-4">
                                                    <h2
                                                        className="accordion-header position-relative"
                                                        id="experienceOne"
                                                    >
                                                        <Button
                                                            className="accordion-button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setToggleSecond(!toggleSecond);
                                                            }}
                                                            role="button"
                                                            id="collapseExample"
                                                        >
                                                            {t("jobSearch.industry")}
                                                        </Button>
                                                        {/* <Tooltip
                                          placement="top"
                                          isOpen={ttop}
                                          target="ttIndustry"
                                          toggle={() => {
                                            setttop(!ttop);
                                          }}
                                        >
                                          Specify the industry you are
                                          interested in to tailor your job
                                          search. Choose from options like
                                          Marketing, IT, Engineering, etc.
                                        </Tooltip> */}
                                                        {/* <span
                                          id="ttIndustry"
                                          className="info-icon filter-info fs-5"
                                        >
                                          <i className="uil uil-info-circle"></i>
                                        </span> */}
                                                    </h2>
                                                    <Collapse isOpen={toggleSecond}>
                                                        <div className="accordion-body">
                                                            <div className="side-title">
                                                                {industries && industries.length && industries.map((industry, index) => {
                                                                    return (
                                                                        <div className="form-check mt-2">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                value={industry.checked}
                                                                                onChange={(e) => handleOnChangeIndustry(e, index)}
                                                                                id={"flexCheckChecked" + index}
                                                                            />
                                                                            <label
                                                                                className="form-check-label ms-2 text-muted"
                                                                                htmlFor={"flexCheckChecked" + index}
                                                                            >
                                                                                {industry.name}
                                                                            </label>
                                                                        </div>
                                                                    )
                                                                })}

                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>

                                                <div className="accordion-item mt-3">
                                                    <h2
                                                        className="accordion-header position-relative"
                                                        id="jobType"
                                                    >
                                                        <Button
                                                            className="accordion-button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setToggleThird(!toggleThird);
                                                            }}
                                                            role="button"
                                                            id="collapseExample"
                                                        >
                                                            {t("jobSearch.typeOfEmployment")}
                                                        </Button>
                                                        {/* <Tooltip
                                          placement="top"
                                          isOpen={ttop}
                                          target="ttType"
                                          toggle={() => {
                                            setttop(!ttop);
                                          }}
                                        >
                                          Specify the type of employment you are
                                          interested in to tailor your job
                                          search. Choose from options like Full
                                          Time, Part Time, etc.
                                        </Tooltip> */}
                                                        {/* <span
                                          id="ttType"
                                          className="info-icon filter-info fs-5"
                                        >
                                          <i className="uil uil-info-circle"></i>
                                        </span> */}
                                                    </h2>
                                                    <Collapse isOpen={toggleThird}>
                                                        <div className="accordion-body">
                                                            <div className="side-title">
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault6"
                                                                        defaultChecked
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault6"
                                                                    >
                                                                        {t("jobSearch.fullTime")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault2"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault2"
                                                                    >
                                                                        {t("jobSearch.partTime")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault3"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault3"
                                                                    >
                                                                        {t("jobSearch.freelance")}
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault4"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault4"
                                                                    >
                                                                        {t("jobSearch.internship")}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>

                                                <div className="accordion-item mt-3">
                                                    <h2
                                                        className="accordion-header position-relative"
                                                        id="datePosted"
                                                    >
                                                        <Button
                                                            className="accordion-button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setToggleFourth(!toggleFourth);
                                                            }}
                                                            role="button"
                                                            id="collapseExample"
                                                        >
                                                            {t("jobSearch.datePosted")}
                                                        </Button>
                                                        {/* <Tooltip
                                          placement="top"
                                          isOpen={ttop}
                                          target="ttDate"
                                          toggle={() => {
                                            setttop(!ttop);
                                          }}
                                        >
                                          Specify the ‘job posted date’ you are
                                          interested in to tailor your job
                                          search. Choose from options like All
                                          time, Recent, etc.
                                        </Tooltip> */}
                                                        {/* <span
                                          id="ttDate"
                                          className="info-icon filter-info fs-5"
                                        >
                                          <i className="uil uil-info-circle"></i>
                                        </span> */}
                                                    </h2>
                                                    <Collapse isOpen={toggleFourth}>
                                                        <div className="accordion-body">
                                                            <div className="side-title form-check-all">
                                                                <div className="form-check">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="checkAll"
                                                                        value=""
                                                                    />
                                                                    <Label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="checkAll"
                                                                    >
                                                                        {t("jobSearch.all")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="datePosted"
                                                                        value="last"
                                                                        id="flexCheckChecked5"
                                                                        checked={isDateChecked}
                                                                        onChange={handleDateOnChange}
                                                                    />
                                                                    <Label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexCheckChecked5"
                                                                    >
                                                                        {t("jobSearch.recent")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="datePosted"
                                                                        value="last"
                                                                        id="flexCheckChecked6"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexCheckChecked6"
                                                                    >
                                                                        {t("jobSearch.thisWeek")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="datePosted"
                                                                        value="last"
                                                                        id="flexCheckChecked7"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexCheckChecked7"
                                                                    >
                                                                        {t("jobSearch.lastTwoWeeks")}
                                                                    </Label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="datePosted"
                                                                        value="last"
                                                                        id="flexCheckChecked8"
                                                                    />
                                                                    <Label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexCheckChecked8"
                                                                    >
                                                                        {t("jobSearch.thisMonth")}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>

                                                <div className="accordion-item mt-3">
                                                    <h2
                                                        className="accordion-header position-relative"
                                                        id="tagCloud"
                                                    >
                                                        <Button
                                                            className="accordion-button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                setToggleFifth(!toggleFifth);
                                                            }}
                                                            role="button"
                                                            id="collapseExample"
                                                        >
                                                            {t("jobSearch.salaryRange")}
                                                        </Button>
                                                        {/* <Tooltip
                                          placement="top"
                                          isOpen={ttop}
                                          target="ttSalary"
                                          toggle={() => {
                                            setttop(!ttop);
                                          }}
                                        >
                                          Specify the ‘salary range’ and
                                          ‘currency’ you are interested in to
                                          tailor your job search. Choose from
                                          options.
                                        </Tooltip> */}
                                                        {/* <span
                                          id="ttSalary"
                                          className="info-icon filter-info fs-5"
                                        >
                                          <i className="uil uil-info-circle"></i>
                                        </span> */}
                                                    </h2>
                                                    <Collapse isOpen={toggleFifth}>
                                                        <div className="accordion-body">
                                                            <div className="mb-3 d-flex align-items-center justify-content-center">
                                                                <label
                                                                    htmlFor="choices-single-categories"
                                                                    className="form-label me-2"
                                                                >
                                                                    {t("jobSearch.currency")}
                                                                </label>
                                                                <select
                                                                    className="form-select currency-field"
                                                                    data-trigger
                                                                    name="choices-single-categories"
                                                                    id="choices-single-categories"
                                                                    aria-label="Default select example"
                                                                >
                                                                    <option value="4">USD</option>
                                                                    <option value="1">LKR</option>
                                                                    <option value="3">AUD</option>
                                                                    <option value="5">CAD</option>
                                                                </select>
                                                            </div>
                                                            <div className="side-title">
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault6"
                                                                        defaultChecked
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault6"
                                                                    >
                                                                        {t("jobSearch.lessThan")} 1000
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault2"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault2"
                                                                    >
                                                                        1000 - 3000
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault3"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault3"
                                                                    >
                                                                        3000 - 5000
                                                                    </label>
                                                                </div>
                                                                <div className="form-check mt-2">
                                                                    <Input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="flexRadioDefault"
                                                                        id="flexRadioDefault4"
                                                                    />
                                                                    <label
                                                                        className="form-check-label ms-2 text-muted"
                                                                        htmlFor="flexRadioDefault4"
                                                                    >
                                                                        {t("jobSearch.moreThan")} 5000
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

export default JobGridHeader;